<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span class="active">•&nbsp;中文</span>&nbsp;/&nbsp;<span @click="comeMyself(`/information/scholarship`)">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeMyself(`/indexCN`)">首页</div>
							<div class="item" >
								<p @click="comePresidnt">关于辰美</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">校长寄语</div>
									<div class="drop-down-item" @click="comeintro">辰美介绍</div>
									<div class="drop-down-item" @click="comeVisit">参观校园</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">辰美架构及管理团队</div>
									<div class="drop-down-item" @click="comeCo">辰美合作单位</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">教学体系</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA艺术学院</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC商学院</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL学制</div>
									<div class="drop-down-item" @click="comeBtec">BTEC学制</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">最新资讯</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">校园动态</div>
									<div class="drop-down-item" @click="comeTVstation">校园原创视频</div>
									<div class="drop-down-item" @click="comeCalendar">校历</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">升学规划</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">升学服务</div>
									<div class="drop-down-item" @click="comeCollege">国外艺术院校</div>
									<div class="drop-down-item" @click="comeForeignCommercial">国外商科院校</div>
								</div>
							</div>
							<div class="item active">
								<p @click="comeStory">招生信息</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">辰美故事</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/rules`)">招生简章</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/scholarship`)">新生奖学金</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/apply`)">在线报名</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/message`)">留言咨询</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contactCN/Consultation`)">联系我们</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contactCN/Consultation`)">联系及咨询方式</div>
									<div class="drop-down-item" @click="comeMyself(`/contactCN/recruit`)">招聘信息</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/recruitbg.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p @click="comeStory">辰美故事</p>
						<p @click="comeMyself(`/informationCN/rules`)">招生简章</p>
						<p class="nav-active">新生奖学金</p>
						<p @click="comeMyself(`/informationCN/qanda`)">Q&A</p>
						<p @click="comeMyself(`/informationCN/apply`)">在线报名</p>
						<p @click="comeMyself(`/informationCN/message`)">留言咨询</p>
						
					</div>
					<div style="display: none;">
						<p >升学服务</p>
						<p class="nav-active">国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p @click="comeCampusNews" >校园动态</p>
						<p class="nav-active">校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p @click="comeTeaching" >SISA艺术学院</p>
						<p @click="comeCommercial">SIBC商学院</p>
						<p @click="comeIgandAlevel">IGCSE、A-level学制</p>
						<p class="nav-active">BTEC学制</p>
						<!-- <p >辰美合作单位</p> -->
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					
					
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<p class="title50">
							奖学金制度
							<br>
							Scholarship System
						</p>
						<img src="../../../public/static/img/scholarship-img1.png" class="img" alt="">
						<p class="title30 title-lineS">新生入学奖学金</p>
						<p class="title22">参加辰美入学测试成绩优秀且符合以下条件之一的学生可申请辰美奖学金，奖学金为1万至50万不等，最高额奖学金为全额奖学金，其奖励额度等同于学生在辰美就读期间的所有学费：</p>
						<p class="title22">
							<br>
							New Student Scholarship
							          Students who achieve excellent results in our admission test and meet one of the following conditions can apply for our scholarship. The scholarship ranges from 10,000 to 500,000RMB. You have the chance to receive a full-ride scholarship, which is equal to the total tuition during your study at Stellart:
							<br>
							<br>
						</p>
						<p class="text18R">
							
							1、在原就读学校综合排名前5%（年级人数超过600人的放宽至10%）的学生；
							<br>
							2、参加教育主管部门举办的艺术类竞赛取得全国二等奖以上或地区前三名的学生；
          
							<br>
							3、参加国际艺术比赛取得前三名的学生；
          
							<br>
							4、获得国际、国内艺术名师推荐的学生；
          
							<br>
							5、雅思6.5分（含6.5分）或托福90分（含90分）以上的学生；
          
							<br>
							6、艺术作品及和个人面试通过辰美团队评估，极其富有创造力且艺术功底卓越的学生。
          
							<br>
					
          
						</p>
						<p class="title30 title-lineS">名校升学奖学金</p>
						<p class="title22">成功考取世界排名前十艺术院校的学生，一次性奖励人民币3至10万元奖学金。</p>
						<p class="text18R">
							（注： 具体颁发条例由辰美国际艺术教育另行制定）
							<br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'scholarship',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
			}
		},
		
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/informationCN/rules");
			},
			comeStory(){
				this.$router.push("/informationCN/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planningCN/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planningCN/College");
			},
			comeService(){
				this.$router.push("/planningCN/service");
			},
			comeCalendar(){
				this.$router.push("/lifeCN/calendar");
			},	
			comeTVstation(){
				this.$router.push("/lifeCN/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/lifeCN/campusNews");
			},
			comeBtec(){
				this.$router.push("/teachingCN/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teachingCN/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teachingCN/commercial");
			},
			comeTeaching(){
				this.$router.push("/teachingCN/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/aboutCN/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/aboutCN/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/aboutCN/introduction");
			},
			comeVisit(){
				this.$router.push("/aboutCN/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/aboutCN/presidentWords");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},

			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 6.34rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.img {
		width: 100%;
		height: auto;
		margin: .6rem 0 0;
	}
	.title-lineS {
	  margin-bottom: .58rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
	    font-size: .18rem;
	    text-align: justify;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R, .title22{
	  line-height: .36rem;
	}
</style>
