<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span class="active">•&nbsp;中文</span>&nbsp;/&nbsp;<span @click="comeMyself(`/index`)">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class="active item item1">首页</div>
							<div class="item" >
								<p @click="comePresidnt">关于辰美</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">校长寄语</div>
									<div class="drop-down-item" @click="comeintro">辰美介绍</div>
									<div class="drop-down-item" @click="comeVisit">参观校园</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">辰美架构及管理团队</div>
									<div class="drop-down-item" @click="comeCo">辰美合作单位</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">教学体系</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA艺术学院</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC商学院</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL学制</div>
									<div class="drop-down-item" @click="comeBtec">BTEC学制</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">最新资讯</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">校园动态</div>
									<div class="drop-down-item" @click="comeTVstation">校园原创视频</div>
									<div class="drop-down-item" @click="comeCalendar">校历</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">升学规划</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">升学服务</div>
									<div class="drop-down-item" @click="comeCollege">国外艺术院校</div>
									<div class="drop-down-item" @click="comeForeignCommercial">国外商科院校</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeStory">招生信息</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">辰美故事</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/rules`)">招生简章</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/scholarship`)">新生奖学金</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/apply`)">在线报名</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/message`)">留言咨询</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contactCN/Consultation`)">联系我们</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contactCN/Consultation`)">联系及咨询方式</div>
									<div class="drop-down-item" @click="comeMyself(`/contactCN/recruit`)">招聘信息</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="middle">
			<div class="banner w">
				<div class="swiper mySwiper">
				      <div class="swiper-wrapper">
				        <div @click="comeMyself(`/aboutCN/presidentWords`)" class="swiper-slide">
									<a  ><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/f377ca69dad9be57d8310ad5aba5bfd1.jpg" alt=""></a>
								</div>
				        <div @click="comeMyself(`/aboutCN/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/300dea5ebb422628d25c9fb6739d0d4a.jpg" alt=""></a>
								</div>
				        <div @click="comeMyself(`/aboutCN/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/d41d641e9eb77bb5c3ebd856087eadd2.jpg" alt=""></a>
								</div>
				      </div>
				      <div class="swiper-button-next"></div>
				      <div class="swiper-button-prev"></div>
							 <!-- <div class="swiper-pagination"></div> -->
				    </div>
			</div>
			<div class="info">
				<div class="w info-item">
					<div class="school-introduce">学校介绍</div>
					<div class="school-img"><img src="../../../public/static/img/item01-img3.png" alt=""></div>
					<div class="school-pic"><img src="../../../public/static/img/item01-img2.jpg" alt=""></div>
					<div class="school-icon"><img src="../../../public/static/img/item01-img1.png" alt=""></div>
					<div class="school-txt">
          辰美艺术教育（SISA）是全国首个根植中国、面向全球的艺术全学科（艺术与设计、音乐、戏剧、艺术商业）教育平台，也是湾区首所剑桥、爱德思双认证的艺术学院。辰美以培养“具有全球胜任力的中国创新人才”为使命，坚持根深中华、融通世界、美育全球，办面向未来、引领未来、成就未来的艺术教育。辰美精心设计的城市校园给学子提供充满想象力与创造力的学习空间，辰美独创丰富多元的课程体系，探索激发学生活力的办学模式，通过特有的“博雅教育”与“胜任力模型”，融合中西文化、艺术与商科综合实践教育，培养具有审美能力、设计思维、美学创造力、全球胜任力的中国未来艺术家和商业领袖，为中国学子提供全方位、一站式海内外名校升学方案。
          </div>
				</div>
				<div class="school-team w">
					<div class="team-title">
						<div class="title-img">
							<img src="../../../public/static/img/teacherteam.png" alt="">
						</div>
						<div class="team-txt">
							教师团队
						</div>
					</div>
					<div class="teacher">
						
						  <!-- Swiper -->
						  <div class="swiper-container">
						    <div class="swiper-wrapper">
						      <div class="swiper-slide ">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/5eb46a467ed1daa6e3a1538ca51a9843.png" alt="">
											</div>
											<p class="teacher-name">
												黄秋艳
											</p>
											<p class="teacher-work">
												校委会主席
											</p>
											<p class="describe">
												辰美校务委员会主席，广东省国际教育促进会副会长，广东省国际艺术教育专业委员会主任，英国教育文化处颁发的华南地区中英校际交流金奖获得者，先后参与了南海实验中学、南海外国语学校等四所学校的创办。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/767451d11d9f78893d8b03b71bd3750a.png" alt="">
											</div>
											<p class="teacher-name">
												陈泽芳 
											</p>
											<p class="teacher-work">
												荣誉校长
											</p>
											<p class="describe">
												特级校长、全国管理创新典范校长，2018年全国最具影响力国际学校校长，广东省留学服务协会国际化学校专委会副主任，华南师范大学教师教育学部兼职教授，华南师范大学附属外国语学校创校校长。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/4ec084b79d36782bc1058975a7d4b9c2.png" alt="">
											</div>
											<p class="teacher-name">
												Roy White
											</p>
											<p class="teacher-work">
												顾问校长
											</p>
											<p class="describe">
											国际学校理事会（CIS）委员，曾任职于英国学校基金会，中国香港著名国际学校的创校校长，有英国、荷兰、北欧等多个国家国际学校校长任职经验，CIS对他领导的每所学校的创新愿景高度赞赏。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/8263460627f530a84488dbc5eed8deab.png" alt="">
											</div>
											<p class="teacher-name">
												张浩然
											</p>
											<p class="teacher-work">
												常务副校长
											</p>
											<p class="describe">
												北京外国语学院硕士，广东省青少年人工智能专业委员会副主任，南海区信息技术学科带头人，南海区信息技术金牌教练，佛山市首批创客导师，参与南海外国语学校等多所民办国际化学校的创办。</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/159fde1da611a42af5d616daf6f6a6d9.png" alt="">
											</div>
											<p class="teacher-name">
												施德王
											</p>
											<p class="teacher-work">
												副校长、艺术顾问
											</p>
											<p class="describe">
												前广美附中国际艺术部学术校长，英国爱德思考试局认可阅卷员和审阅员。获得伦敦艺术大学切尔西学院室内空间设计研究生学位，曾共同设计伦敦艺术大学在华研究生预备课程，有9年国际艺术教育管理经验，包括艺术预科及艺术高中经验，多年来培养超过1000名学生升读海外艺术名校。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/00f058c64ff277f7f6a0b8a5113f684a.png" alt="">
											</div>
											<p class="teacher-name">
												李雅靖
											</p>
											<p class="teacher-work">
												资源拓展总监
											</p>
											<p class="describe">
												（毕业于美国加州大学圣地亚哥分校，获得管理科学学士（拉丁荣誉毕业）、商学和心理学双副学士、康奈尔大学市场战略证书，曾就职于美国咨询行业和香港风投行业，有英国、美国、中国香港多所大学的丰富教育资源。</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220801/0b7a97e239bb433f2b33839ccda82d14.jpg" alt="">
											</div>
											<p class="teacher-name">
												Martin Bills
											</p>
											<p class="teacher-work">
												音乐学院院长
											</p>
											<p class="describe">
												英国索尔福德大学音乐学士和哈德斯菲尔德大学作曲硕士。不仅是屡获殊荣的词曲作者和作曲家，还是一位获奖艺术家。专业作品包括为剧院、电影和当代舞蹈团体创作的音乐作品。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20210719/fc1300635e31c3b9dc6af82d08f5d65b.png" alt="">
											</div>
											<p class="teacher-name">
												杨熙
											</p>
											<p class="teacher-work">
												杨熙
											</p>
											<p class="describe">
												毕业于澳大利亚科廷大学和香港大学，研究生学历，主修会计和金融。澳大利亚注册会计师，曾就职国际金融服务机构。加入辰美前，在中国和澳洲高校任教并担任课程内审员及教学主管副院长，持有澳洲高等教育教师资格、国际文凭组织颁发的国际文凭教师证书、剑桥大学考试委员会英语教学能力证书。
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/8285f45fed7b8da89f06044dc00c20fe.png" alt="">
											</div>
											<p class="teacher-name">
												Brian Reverma
											</p>
											<p class="teacher-work">
												学术顾问
											</p>
											<p class="describe">
												美国戴顿大学艺术学士，俄亥俄州立大学艺术硕士，拥有30多年经验的艺术家和教育家，“理想主义艺术教师实用指南”系列丛书作者YouTube上ISB艺术史频道的制片人，Inspire Citizens视觉艺术顾问，亚洲地区艺术教育家协会(亚洲地区艺术教师联盟)管理者。</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/9da91f58cd585a177ce895edadd68b79.png" alt="">
											</div>
											<p class="teacher-name">
												Colin Kay
											</p>
											<p class="teacher-work">
												学术顾问
											</p>
											<p class="describe">
												毕业于剑桥大学，拥有国际教学领导专业MBA学位，英国皇家艺术学会的成员，曾担任多年英国高中校长，现任布里斯托学院信托基金会教育顾问、巴斯大学教育领导专业研究生课程的副讲师。</p>
										</div>
									</div>
						
						    </div>
						    
						    <div class="ture-left-botton"></div>
						    <div class="ture-right-button"></div>
						  </div>	
					</div>
					<img class="more-img" src="../../../public/static/img/more-black.png" @click="comeMyself(`/about/architectureAndTeam`)" alt="">
				</div>	
				<div class="object w">
					<div class="object-box">
						<img class="object-icon" src="../../../public/static/img/item03-img1.png" alt="">
						<img class="object-title-icon" src="../../../public/static/img/item03-img3.png" alt="">
						<img class="object-teacher-img" src="../../../public/static/img/item03-img2.jpg" alt="">
						<div class="object-title">
							课程介绍
						</div>
						<div class="object-content">
							
 SISA建立了包括学术基础课程、全面素质培养课程、探究性课程在内的三维课程体系，通过项目式学习、设计思维学习、STEAM教育、通感教育、跨专业学习，为学生成长为艺术家奠定全面基础的同时，帮助学生进行人格塑造，培养对自我的认识和对社会的认知。
							          SIBC打造独具特色的“六三一”商科课程模式，SIBC的课程包括了60%的国际学术课程，30%的商业案例、商业素养课程，10%的商业实践课程。校内专门设置全仿真模拟公司、商业沙盘实战室，连接行业一线实训基地，通过融合式学习、游戏化学习、社会化学习为学生提供学研结合的成长模式，帮助学生进入全球顶尖商科院校的金融、管理、会计、经济、新型商科等专业学习。   
						</div>
					</div>
					<div class="school-object-list">
						<div class="sisa-object" @click="comeMyself(`/teachingCN/sixCollegesList`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img4.png" alt="">
							<div class="sisa">
								<div class="sisa-title">
									SISA
								</div>
								<div class="sisa-content">艺术学院</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/teachingCN/commercial`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img5.png" alt="">
							<div class="sisa">
								<div class="sisa-title">SIBC</div>
								<div class="sisa-content">商学院</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/planningCN/service`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
							<div class="sisa">
								<div class="sisa-title">UNIVERSITY COUNSELING</div>
								<div class="sisa-content">升学</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
					</div>
					<div class="object-more">
						<img class="object-more-img" src="../../../public/static/img/more-white.png" @click="comeMyself(`/teachingCN/sixColleges`)" alt="">
					</div>
				</div>
				<div class="school-style w">
					<div class="school-style-box">
						<img class="style-img" src="../../../public/static/img/item04-img2.png" alt="">
						<img class="style-title-icon" src="../../../public/static/img/item04-img3.png" alt="">
						<img class="style-icon" src="../../../public/static/img/item04-img1.png" alt="">
						<div class="school-style-title">校园风采</div>
						<div class="school-style-txt need-line">
          艺术&商业教育的核心是培养未来人才的美学创造力。辰美作为一所多元化、城市化的现代创新型学校，是中国美育教育的见证者，是少年商学院的领航人。辰美不只是一所独立的学校，更是一个能连接社会资源、成为学习实践之地的区域艺术激发器、未来商业领袖孵化基地。在辰美，学生能尽情展现自己的想法和创意，成为具有全球胜任力的中国创新人才。          
						</div>
					</div>
					<div class="school-object-list">
						<div class="sisa-object" @click="comeMyself(`/informationCN/rules`)">
							<img class="sisa-img" src="../../../public/static/img/item04-img4.png" alt="">
							<div class="sisa">
								<div class="sisa-title">
									Admissions and Prospect
								</div>
								<div class="sisa-content">招生简章</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/lifeCN/campusNews`)">
							<img class="sisa-img" src="../../../public/static/img/item04-img5.png" alt="">
							<div class="sisa">
								<div class="sisa-title">News</div>
								<div class="sisa-content">校园动态</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/lifeCN/TVstation`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
							<div class="sisa">
								<div class="sisa-title">Videos</div>
								<div class="sisa-content">原创视频</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
					</div>
					<div class="object-more">
						<img class="object-more-img" src="../../../public/static/img/more-white.png" alt="">
					</div>
				</div>
				<div class="greet-link">
					<p class="greet-title">辰美欢迎您</p>
					<img class="greet-title-icon" src="../../../public/static/img/item05-img1.png" alt="">
					<p class="greet-txt">
						
        辰美只等你来！点击下方链接
        <br />了解关于辰美的招生介绍、入学方式、报名等资讯艺术
      
					</p>
					<img class="greet-message" src="../../../public/static/img/icon-more2G.png" @click="comeMyself(`/information/rules`)" alt="">
					<div class="greet-my">
						<img src="../../../public/static/img/icon-more3G.png" @click="comeMyself(`/contact/Consultation`)" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
        联系电话：0757-86796295
        </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			<div class="ip-come" @click="detailsRight">
				系统入口
			</div>
			<div class="come-top" :class="{
				'come-top-show': showBtn,
				'come-top-hidden': hiddenBtn,
			}" @click="comeTop"></div>
			<div class="details-bg" v-if="detailShow">
				<div class="details-box">
					<div class="oa-box">
						<div class="oa">
							<a href="https://sisa.managebac.cn/login">
								<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
								<p>ManageBac</p>
							</a>
						</div>
					</div>
					<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
				</div>
			</div>
			<div class="sidebar">
				<img class="sidebar-img1" src="../../../public/static/img/边栏-在线报名.png" @click="comeMyself(`/information/apply`)" alt="">
				<img class="sidebar-img2" src="../../../public/static/img/边栏-实时资讯.png" @click="comeMyself(`/information/message`)" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// import Swiper from "../../../node_modules/swiper/swiper-bundle.min.js"
	import Swiper from "../../api/swiper.min.js"
	// import SwiperSlide from "vue-awesome-swiper"
	export default{
		name:'index',
		data(){
			return{
				active:0,
				detailShow:false,
				showBtn:false,
				hiddenBtn:true,
			}
		},
		mounted() {
			this.myswiper()
			this.swiperCon()
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/informationCN/rules");
			},
			comeStory(){
				this.$router.push("/informationCN/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planningCN/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planningCN/College");
			},
			comeService(){
				this.$router.push("/planningCN/service");
			},
			comeCalendar(){
				this.$router.push("/lifeCN/calendar");
			},	
			comeTVstation(){
				this.$router.push("/lifeCN/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/lifeCN/campusNews");
			},
			comeBtec(){
				this.$router.push("/teachingCN/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teachingCN/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teachingCN/commercial");
			},
			comeTeaching(){
				this.$router.push("/teachingCN/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/aboutCN/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/aboutCN/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/aboutCN/introduction");
			},
			comeVisit(){
				this.$router.push("/aboutCN/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/aboutCN/presidentWords");
			},
			myswiper(){
				var swiper = new Swiper(".mySwiper", {
				  spaceBetween: 20,
				  centeredSlides: true,
				  autoplay: {
				    delay: 2500,
				    disableOnInteraction: false,
				  },
				  pagination: {
				    el: ".swiper-pagination",
				    clickable: true,
				  },
				  navigation: {
				    nextEl: ".swiper-button-next",
				    prevEl: ".swiper-button-prev",
						
				  },      
			 });	
			},
			swiperCon(){
				var swiper = new Swiper('.swiper-container', {
				      slidesPerView: 6,
				      spaceBetween: 30,
					  // centeredSlides: true,
					  // loop: true,
				      pagination: {
				        el: '.swiper-pagination',
				        clickable: true,
				      },
							navigation: {
							  nextEl: ".ture-right-button",
							  prevEl: ".ture-left-botton",
								disabledClass: 'my-button-disabled',
							},
							on:{
								transitionEnd:()=>{
									this.active = swiper.activeIndex;
									console.log(this.active)
								}
							}
				    });
						 var lis = document.querySelectorAll(".swiper-slide");
						 var list = document.getElementsByClassName("swiper-container ")[0];						 
						 lis.forEach(item => {
						     item.onmouseover = () => {
									 var x = list.childNodes[0].childNodes[this.active];
						        //先全部清空选中，再给当前添加选中
						        lis.forEach(item => item.classList.remove('swiper-slide-active'));
										console.log(x)
										// console.log(item.classList)
						        item.classList.add('swiper-slide-active');
						     }
						 })
						 lis.forEach(item => {
						     lis.forEach(item => {
						         item.onmouseout = () => {
												var x = list.childNodes[0].childNodes[this.active];
						            //先全部清空选中，再给当前添加选中
						            lis.forEach(item => item.classList.remove('swiper-slide-active'));
												x.classList.add('swiper-slide-active');
						         }
						     })
						 })
					// swiper.progress;
					// var pe = document.getElementById('progressEffect');
					// pe.onchange = function(){ 
					//     effect = this.value
					// 	swiper.update();	
					// }
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
	@import url("../../../node_modules/swiper/swiper-bundle.css");
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 6.34rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
.middle{
	margin: 0;
	padding: 0;
	/* width: 100%; */
	/* height: ; */
}
.banner img{
	width: 100%;
	height: 9.21rem;
	margin-top: 1.23rem;
	display: flex;
}
.info{
	width: 100%;
	height: auto;
}
.info-item{
	position: relative;
	width: 14.41rem;
	height: 6.22rem;
	background-color: white;
	margin-top: 2.5rem;
	margin-bottom: 1.27rem;
}
.school-introduce{
	position: absolute;
	left: 0;
	font-weight: 400;
	color: #3e3a39;
	top: .02rem;
	font-size: .4rem;
	line-height: .4rem;
}
.school-img{
	position: absolute;
	width: 5.08rem;
	position: absolute;
	left: .02rem;
	top: .58rem;
	z-index: 2;
}
.school-pic{
	width: 10.15rem;
	height: 5.7rem;
	position: absolute;
	right: 0;
	bottom: .52rem;
	z-index: 1;
}
.school-img img{
	width: 100%;
	height: 100%;
}
.school-pic img{
	width: 100%;
	height: 100%;
}
.school-txt{
	position: absolute;
	width: 3.6rem;
	font-size: .16rem;
	line-height: .28rem;
	letter-spacing: -.01rem;
	text-align: justify;
	top: 3.05rem;
	left: 0;
	font-weight: 400;
	color: #3e3a39;
}
.school-icon{
	width: 1.05rem;
	height: auto;
	position: absolute;
	right: -.52rem;
	bottom: 0;
	z-index: 2;
}
.team-title{
	width: 100%;
	margin-bottom: .35rem;
}
.school-team{
	height: 8.66rem;
	margin-bottom: 4.38rem;
	position: relative;
}
.title-img img{
	display: block;
	margin: 0 auto;
	width: 3.68rem;

}
.team-txt{
	font-size: .4rem;
	padding: 0;
	margin-top: .1rem;
	text-align: center;
}
.teacher{
	margin: 0 .34rem;
	padding: .57rem .41rem 0;
	overflow: hidden;
}
.teacher-introduce{
	width: 100%;
	height: 100%;
}
.teacher-name{
	font-size: .16rem;
	font-weight: 700;
	padding: 0;
	color: #1f2c5c;
	opacity: 1;
}
.teacher-work{
	font-size: .16rem;
	font-weight: 700;
	padding: 0;
	color: #1f2c5c;
	opacity: 1;
}
.teacher .describe{
	font-size: .14rem;
	line-height: .24rem;
	width: 2.44rem;
	margin: .06rem -.3rem;
	height: auto;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 11;
	-webkit-box-orient: vertical;
	text-align: justify;
	opacity: 0;
	transition: all .4s;
	-webkit-transition: all .4s;
	border-top: 1px solid #bab9b9;
	border-bottom: 1px solid #bab9b9;
	padding: 2px 0;
}
/* .teacher-introduce:hover .describe{
	opacity: 1;
} */
/* .teacher-introduce:hover .teacher-img img{
	padding: .15rem;
	border-radius: 50%;
	cursor: pointer;
	z-index: 10;
	border: .01rem solid #939ab1;
	transition: 1s;
	margin-bottom: 30px;
	transform: scale(1.5);
} */
.ture-right-button{
	    background: url(//sisa.p1.yixininteractive.com/pro/sisa/icon/icon-right.png) no-repeat top/100% auto;
	    width: .39rem;
	    height: 1.14rem;
	    position: absolute;
	    top: 2.05rem;
	    right: -.1rem;
}
.teacher .ture-left-botton{
	background: url(//sisa.p1.yixininteractive.com/pro/sisa/icon/icon-left.png) no-repeat top/100% auto;
	    width: .39rem;
	    height: 1.14rem;
	    position: absolute;
	    top: 2.05rem;
	    left: -.1rem;

}
.swiper {
    width: 100%;
    /* height: 100%; */
		overflow: hidden; 
}
.banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
/*    display: -webkit-flex;
    display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.banner .swiper-wrapper{
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}
.banner .swiper-slide img {
    width: 100%;
        height: 9.21rem;
        margin-top: 1.23rem;
        display: flex;
}
    .swiper-container {
      width: 100%;
      height: auto;
			/* padding:90px 0; */
      margin-left: auto;
      margin-right: auto;
			/* overflow: hidden; */
    }
  .swiper-container  .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
/* 			width: 121.975px !important;
			margin-right: 42.83px !important; */
      /* Center slide text vertically */
       display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
       /*     -webkit-align-items: center;
            align-items: center; */
    }
	.teacher  img{
		width: 1.33rem;
		height: 1.33rem;
		border-radius: 50%;
		/* transition: all 0.5s; */

		/* align-items: center; */

	}
	.more-img{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 8rem;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.swiper-slide-active .teacher-introduce .teacher-img img{
		padding: .15rem;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: .01rem solid #939ab1;
		/* border: 1px solid #000; */
		/* opacity: 0.2; */
		transition: 0.6s;
		margin-bottom: 30px;
		transform: scale(1.5);
		position: relative;
		z-index: 10;
	}
	.swiper-slide-active .describe{
		opacity: 1;
	}
	.my-button-disabled{
		opacity: 0;
	}
	.object-box{
		width: 14.41rem;
		height: 6.65rem;
		margin-bottom: 1.19rem;
		position: relative;
	}
	.object-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		left: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.object-teacher-img{
		width: 9.19rem;
		height: 6.13rem;
		position: absolute;
		left: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.object-title-icon{
		width: 7.22rem;
		position: absolute;
		right: .02rem;
		top: .75rem;
		z-index: 2;
	}
	.object-title{
		position: absolute;
		right: 0;
		top: .18rem;
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 400;
    color: #fff;
	}
	.object-content{
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		width: 4.6rem;
		font-size: .16rem;
		line-height: .28rem;
		text-align: justify;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		top: 3.3rem;
	}
	.sisa-object{
		cursor: pointer;
		position: relative;
	}
	.school-object-list{
		display: flex;
		justify-content: space-between;
	}
	.sisa-img{
		width: 4.59rem!important;
		height: 4.59rem!important;
	}
	.sisa{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 1rem;
		z-index: 3;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
	.sisa-title{
		font-weight: 700;
		font-size: .28rem;
		line-height: .46rem;
		border-bottom: 2px solid #fff;
	}
	.sisa-content{
		font-weight: 400;
		font-size: .2rem;
		line-height: .28rem;
		margin-top: .27rem;
	}
	.sisa-title,.sisa-content{
		color: #fff;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.sisa-mask{
		position: absolute;
		top: 0;
		width: 4.59rem;
		height: 4.59rem;
		opacity: 0;
		background-color: rgba(0,0,0,.4);
		z-index: 2;
	}
	.sisa-object:hover .sisa-mask{
		transition: 0.6s;
		opacity: 1;
	}
	.sisa-object:hover .sisa-content{
		transition: 0.6s;
		font-size: .27rem;
	}
	.sisa-object:hover .sisa-title{
		transition: 0.6s;
		font-size: .32rem;
	}
	.object-more{
		margin-top: .89rem;
		width: 1.22rem;
		position: relative;
		left: 44%;
	}
	.object-more-img{
		position: absolute;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.object{
		width: 14.41rem;
		margin: auto auto 6.34rem;
	}
	.school-style-box{
		width: 14.41rem;
		height: 6.22rem;
		margin-bottom: 1.19rem;
		position: relative;
	}
	.style-img{
		width: 10.14rem;
		height: 5.7rem;
		position: absolute;
		right: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.style-title-icon{
		width: 8.89rem;
		position: absolute;
		left: .02rem;
		top: .59rem;
		z-index: 2;
	}
	.style-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		right: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.school-style-title{
		top: .01rem;
		left: 0;
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		font-size: .4rem;
		line-height: .4rem;
	}
	.school-style-txt{
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
		width: 3.6rem;
		font-size: .16rem;
		line-height: .28rem;
		text-align: justify;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.school-style{
		margin-bottom: 7.68rem;
	}
	.greet-link{
		padding-bottom: 7.77rem;
	}
	.greet-title{
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 400;
		color: #3e3a39;
		margin-bottom: .21rem;
		text-align: center;
	}
	.greet-title-icon{
		width: 4.54rem;
		height: auto;
		display: block;
		margin: auto auto .9rem;
	}
	.greet-txt{
		font-size: .2rem;
		font-weight: 400;
		color: #3e3a39;
		line-height: .28rem;
		text-align: center;
		margin-bottom: .45rem;
	}
	.greet-message{
		width: 1.22rem;
		left: 7.8rem;
		position: absolute;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.greet-my{
		left: 10.18rem;
		width: 1.22rem;
		cursor: pointer;
		position: relative;
	}
	.greet-my img{
		position: absolute;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.sidebar{
		width: 1.08rem;
		height: 2.16rem;
		position: fixed;
		right: 0;
		bottom: 2rem;
		z-index: 99;
		flex-direction: column;
		display: flex;
	}
	.sidebar img{
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
	}
	.need-line{
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.sidebar-img1{
		cursor: pointer;
	}
	.sidebar-img2{
		cursor: pointer;
	}
</style>