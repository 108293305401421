<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span @click="comeMyself(`/lifeCN/TVstation`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeIndex">Home</div>
							<div class="item" >
								<p @click="comePresidnt">About Us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">Principal Message</div>
									<div class="drop-down-item" @click="comeintro">Introduction</div>
									<div class="drop-down-item" @click="comeVisit">Campus Tour</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">Structure and Management Team</div>
									<div class="drop-down-item" @click="comeCo">Our Partners</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">Teaching</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL</div>
									<div class="drop-down-item" @click="comeBtec">BTEC</div>
								</div>
							</div>
							<div class="item active">
								<p @click="comeCampusNews">News</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">Campus News</div>
									<div class="drop-down-item">Videos</div>
									<div class="drop-down-item" @click="comeCalendar">Calendar</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">University Counseling</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">University Counseling Service</div>
									<div class="drop-down-item" @click="comeCollege">Foreign art schools</div>
									<div class="drop-down-item" @click="comeForeignCommercial">Foreign Business Colleges</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeStory">Admissions</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">SISA Story</div>
									<div class="drop-down-item" @click="comeRules">Admissions Brochure</div>
									<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">New Student Scholarship</div>
									<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/information/apply`)">Online Registration</div>
									<div class="drop-down-item" @click="comeMyself(`/information/message`)">Message</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contact/Consultation`)"> Contact us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contact/Consultation`)">Contact</div>
									<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">Recruitment</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/container-banner.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div >
						<p @click="comeCampusNews">Campus News</p>
						<p class="nav-active"> Videos</p>
						<p @click="comeCalendar">Calendar</p>
						
					</div>
					<div style="display: none;">
						<p @click="comeTeaching" >SISA艺术学院</p>
						<p @click="comeCommercial">SIBC商学院</p>
						<p @click="comeIgandAlevel">IGCSE、A-level学制</p>
						<p class="nav-active">BTEC学制</p>
						<!-- <p >辰美合作单位</p> -->
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					<div style="display: none;">
						<p class="">辰美故事</p>
						<p>招生简章</p>
						<p>新生奖学金</p>
						<p>Q&A</p>
						<p>在线报名</p>
						<p>留言咨询</p>
					</div>
					
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					<div style="display: none;">
						<p class="">升学服务</p>
						<p>国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<div class="flex">
							<img src="../../../public/static/img/icon-dangqi.png" class="icon-dangqi" alt="">
							<p class="title30">当期推荐</p>
						</div>
						<div class="new">
							<div class="new-left" @click="comeUrl(TVurl)">
								<img :src="TVimage" class="video-bg" alt="">
								<div class="zhebi6"></div>
								<img src="../../../public/static/img/icon-player.png" class="icon-player" alt="">
								<img src="../../../public/static/img/最新角标.png" class="icon-new" alt="">
								<p class="video-text1">{{TVtext}}</p>
							</div>
							<div class="new-right">
								<div class="swiper mySwiper">
									<div class="swiper-wrapper" style="display: block;">
										<div class="swiper-slide" @click="showTV(tv.attributes.image,tv.attributes.title,tv.attributes.url)" v-for="(tv,i) in TVList"> 
											<div class="right-card cu" >
												<div class="right-cardnei" >
													<img :src="tv.attributes.image" class="video-bg" alt="">
													<div class="zhebi6" >
														
													</div>
													<p class="video-text2">{{tv.attributes.title}}</p>
												</div>
											</div> 
										</div>
										<div class="swiper-slide">
											<div class="right-card cu">
												<div class="right-cardnei end">
													<img src="../../../public/static/img/最新动态人物.png" class="video-bg" alt="">
													<div class="zhebi6">
														
													</div>
													<p class="video-text3">
														
														持续更新
														<br>
														敬请期待
                      
													</p>
												</div>
											</div> 
										</div>
										<!-- <div class="swiper-slide">Slide 7</div>
										<div class="swiper-slide">Slide 8</div>
										<div class="swiper-slide">Slide 9</div> -->
									</div>
									
									
									<!-- <div class="swiper-pagination"></div> -->
								</div>
								<div class="swiper-button-next"></div>
								<div class="swiper-button-prev"></div>
							</div>
						</div>
						<div>
							<div class="TVlist">
								<div class="flex">
									<img src="../../../public/static/img/icon-interview.png" alt="">
									<p class="title30">辰美校长访谈</p>
								</div>
								<div class="video-list ">
									<div class="video-box" @click="comeMyself(`/life/TVPlayer`)">
										<div class="video-img">
											<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20210506/b40f27c547325e9e7074982d91260f20.jpg" class="img1" alt="">
											<div class="player-bg">
												
											</div>
											<img src="../../../public/static/img/icon-player.png" class="icon-player" alt="">
										</div>
										<div class="video-text">
											<div class="line">
												<img src="../../../public/static/img/视频主题icon.png" class="title-icon" alt="">
												<p class="text1">在辰美，成为具有全球胜任力的艺术创新人才</p>
												
											</div>
											<p class="text2"></p>
										</div>
									</div>
									
								</div>
								<div class="data-more" @click="showTVMore" >
									<span v-if="!moreTVShow">收起</span>
									<span v-if="moreTVShow">查看更多</span>
									<img src="../../../public/static/img/查看更多.png" class="tvdown" alt="">
								</div>
								<!-- <div class="data-more" @click="showTVMore" v-if="!moreTVShow">
									<span>收起</span>
									<img class="down-img" src="../../../public/static/img/查看更多.png" class="" alt="">
								</div> -->
							</div>
							
						</div>
						<div>
							<div class="TVlist">
								<div class="flex">
									<img src="../../../public/static/img/icon-yun.png" alt="">
									<p class="title30">辰美云课堂&创意课堂</p>
								</div>
								<div class="video-list">
									<div :class="item.attributes.isShow==0 ? 'miss':''" class="video-box" @click="comeUrl(item.attributes.url)" v-for="(item,i) in inList">
										<div class="video-img">
											<img :src="item.attributes.image" class="img1" alt="">
											<div class="player-bg">
												
											</div>
											<img src="../../../public/static/img/icon-player.png" class="icon-player" alt="">
										</div>
										<div class="video-text">
											<div class="line">
												<img src="../../../public/static/img/视频主题icon.png" class="title-icon" alt="">
												<p class="text1">{{item.attributes.title}}</p>
												
											</div>
											<p class="text2">{{item.attributes.description}}</p>
										</div>
									</div>
								</div>
								<div class="data-more" @click="showVideoMore" >
									<span v-if="!moreVideoShow">收起</span>
									<span v-if="moreVideoShow">查看更多</span>
									<img src="../../../public/static/img/查看更多.png" class="tvcandown" alt="">
								</div>
					<!-- 			<div class="data-more" @click="showVideoMore" >
									
									<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	import {  getTV,getrecomdlist,gettvinterviews } from "../../api/api.js"
	import Swiper from "../../api/swiper.min.js"
	export default{
		name:'TVstation',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
				moreVideoShow:true,
				moreTVShow:true,
				TVimage:'',
				TVtext:'',
				TVList:[],
				inList:[],
				TVurl:'',
			}
		},
		
		mounted() {
			this.myswiper()
			window.addEventListener("scroll", this.showbtn, true)
			this.hiddenPic()
			this.getTvList()
			this.getRecomdlist()
		},
		activated() {
			this.getTvList()
		},
		methods:{
			comeUrl(e){
				window.location.href = e
			},
			getTvList(){
				getTV().then(res=>{
					// console.log(res.data.data,'dahgiodha')
					this.TVList = res.data.data
					// console.log(this.TVList,'dahd')
					this.TVimage = res.data.data[0].attributes.image
					this.TVtext = res.data.data[0].attributes.title
					this.TVurl = res.data.data[0].attributes.url
				})
			},
			getRecomdlist(){
				getrecomdlist().then(res=>{
					this.inList = res.data.data
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/index");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showTV(image,txt,tvURL){
				console.log(image)
				console.log(txt)
				
				this.TVimage = image
				this.TVtext = txt
				this.TVurl = tvURL
 			},
			myswiper(){
				var swiper = new Swiper(".mySwiper", {
					direction: 'vertical',
					slidesPerView: 3,
					// spaceBetween: 30,
					slidesPerGroup: 1,
					// height:301,
					// autoHeight: true,
					// loop: true,
					loopFillGroupWithBlank: true,
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
					},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				});
			},
			hiddenPic(){
				var lis = document.querySelectorAll(".video-box");
				console.log(lis[1])
				for(var i = 0;i<=lis.length-1;i++){
					if(i>=5){
						lis[i].classList.add('miss')
					}
				}
			},
			showTVMore(){
				if(this.moreTVShow){
					this.moreTVShow = false
					var lis = document.querySelectorAll(".tvdown");
					console.log(lis[0].style.transform)
					lis[0].classList.add('tvrote')
					// lis[0].style.transform="180deg";
					// console.log(lis[0].style.transform)
					// this.videoShow = true
				}else{
					this.moreTVShow = true
					var lis = document.querySelectorAll(".tvdown");
						lis[0].classList.remove('tvrote')
				}
			},
			showVideoMore(){
				if(this.moreVideoShow){
					this.moreVideoShow = false
					var lis = document.querySelectorAll(".video-box");
					var tvlis = document.querySelectorAll(".tvcandown");
						tvlis[0].classList.add('tvrote')
					// console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.remove('miss')
						}
					}
					// this.videoShow = true
				}else{
					this.moreVideoShow = true
					var lis = document.querySelectorAll(".video-box");
					var tvlis = document.querySelectorAll(".tvcandown");
						tvlis[0].classList.remove('tvrote')
					// console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.add('miss')
						}
					}
				}
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 7.66rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}

.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
		
	.flex{
		display: flex;
		align-items: center;
	}
	.icon-dangqi{
		width: .25rem;
		height: .26rem;
		margin-right: .08rem;
	}
	.title30{
		font-weight: 700;
		color: #0e183f;
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 0;
	}
	.new{
		margin-top: .4rem;
		margin-bottom: .9rem;
		justify-content: space-between;
		display: flex;
	}
	.new-left{
		cursor: pointer;
		width: 7.49rem;
		height: 4.41rem;
		position: relative;
	}
	.new-right{
		width: 1.92rem;
		height: 4.01rem;
		padding-top: .4rem;
		position: relative;
	}
	.video-bg{
		width: 100%;
		height: 100%;
	}
	.zhebi6{
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgba(0,0,0,.6);
		left: 0;
		top: 0;
	}
	.new .icon-player{
		position: absolute;
		left: 0;
		top: 0;
		width: .96rem;
		height: .96rem;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 3;
	}
	.icon-new{
		width: .94rem;
		height: .94rem;
		position: absolute;
		left: 0;
		top: 0;
	}
	.video-text1{
		width: 6.89rem;
		height: .61rem;
		padding-left: .6rem;
		line-height: .61rem;
		background: rgba(233,71,20,.6);
		font-size: .26rem;
		font-family: SourceHanSansCN-Regular;
		font-weight: 400;
		color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.mySwiper{
		height: 3.62rem;
		margin: 0;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
	}
	.swiper-wrapper{
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: block;
		transition-property: transform;
		box-sizing: content-box;
	}
	.swiper-slide{
		    flex-shrink: 0;
		    width: 100%;
		    height: 100%;
		    position: relative;
		    transition-property: transform;
				margin-bottom: 0 !important;
		/* border: 2px solid #ea5431; */
		    /* height: 80.3333px !important; */
		/* margin-bottom: .1rem !important; */
	}
	.right-card{
		height: 1.13rem;
		cursor: pointer;	
	}
	.right-cardnei{
		width: 1.86rem;
		height: 1.09rem;
		position: relative;
		display: inline-block;
		border: 2px solid #ea5431;
	}
	.right-cardnei .zhebi6{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0,0,0,.3);
	}
	.video-text2{
		width: 1.74rem;
		font-size: 12px;
		font-family: SourceHanSansCN-Regular;
		font-weight: 400;
		color: #fff;
		line-height: .2rem;
		position: absolute;
		bottom: .12rem;
		left: .14rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.swiper-button-next, .swiper-button-prev {
	    position: absolute;
	    top: 50%;
	    width: 27px;
	    width: calc(var(--swiper-navigation-size)/44*27);
	    height: 44px;
	    height: var(--swiper-navigation-size);
	    margin-top: -22px;
	    margin-top: calc(var(--swiper-navigation-size)*-1/2);
	    z-index: 10;
	    cursor: pointer;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    color: var(--swiper-theme-color);
	    color: var(--swiper-navigation-color,var(--swiper-theme-color));
	}
	.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	    left: 10px;
	    right: auto;
	}
	.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
	    opacity: .35;
	    cursor: auto;
	    pointer-events: none;
	}
	.swiper-button-prev{
		width: 100%;
		height: .28rem;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
	}
	.swiper-button-next {
	    width: 100%;
	    height: .28rem;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    top: unset;
	    margin: 0;
	}
	.swiper-button-next:after, .swiper-button-prev:after {
	    font-family: swiper-icons;
	    font-size: 44px;
	    font-size: var(--swiper-navigation-size);
	    text-transform: none!important;
	    letter-spacing: 0;
	    text-transform: none;
	    font-feature-settings: normal,;
	    font-variant: normal;
	}
	.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
	    content: "prev";
	}
	.swiper-button-prev::after{
		content: "";
		background: url(../../../public/static/img/video-up.jpg) no-repeat top/100% auto;
		width: 100%;
		height: .28rem;
	}
	.swiper-button-next::after {
	    content: "";
	    background: url(../../../public/static/img/video-down.jpg) no-repeat top/100% auto;
	    width: 100%;
	    height: .28rem;
	}
	.video-text3{
		height: .5rem;
		text-align: center;
		font-size: .19rem;
		font-weight: 500;
		color: #fff;
		line-height: .26rem;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: auto;
	}
	.end .zhebi6{
		background: linear-gradient(0deg,#ea5514,#e60312);
		opacity: .73;
	}
	.TVlist{
		margin-bottom: .45rem;
	}
	.icon-interview{
		margin-right: .08rem;
		width: .25rem;
		height: .26rem;
	}
	.video-list{
		margin-top: .37rem;
		-webkit-box-pack: justify;
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
	}
	.video-box, .video-box-none{
		width: 2.26rem;
		height: 2.32rem;
	}
	.video-box{
		overflow: hidden;
		border-radius: 0 0 .2rem 0;
		cursor: pointer;
		margin-bottom: .2rem;
	}
	.video-img, .video-img .img1{
		width: 2.26rem;
		height: 1.34rem;
	}
	.video-img {
	  position: relative;
	}
	.video-img .img1{
		display: flex;
	}
	.player-bg{
		position: absolute;
		left: 0;
		top: 0;
		width: 2.26rem;
		height: 1.34rem;
		background-color: rgba(0,0,0,.6);
		z-index: 2;
	}
	.icon-player{
		position: absolute;
		left: 0;
		top: 0;
		width: .54rem;
		height: .54rem;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 3;
	}
	.video-text{
		width: 100%;
		height: .98rem;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.video-text .line{
		height: .39rem;
		border-bottom: 1px solid #bfbfbf;
		align-items: center;
		display: flex;
	}
	.title-icon{
		width: .09rem;
		height: .14rem;
		display: flex;
		margin-left: .12rem;
		margin-right: .07rem;
	}
	.video-box .text1{
		flex: 1;
		margin-right: .12rem;
		font-size: .15rem;
		font-weight: 700;
		line-height: .39rem;
		white-space: nowrap;
	}
	.video-box .text2{
		font-size: 12px;
		font-weight: 400;
		line-height: .21rem;
		width: 1.71rem;
		margin-left: .27rem;
		margin-top: .1rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.video-box .text1, .video-box .text2{
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .3s;
		-webkit-transition: all .3s;
	}

	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		display: flex;
		transition: all .3s;
	}
	.data-more span{
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	.down-img{
		
		transform: rotate(180deg);
	}
	.tvdown{
		transition: all .9s;
		transform: rotate(0);
	}
	.tvcandown{
		transition: all .9s;
		transform: rotate(0);
	}
	.data-more .tvrote{
		transition: all .9s;
		transform: rotate(180deg);
	}
	.miss{
		
		display: none !important;
	}
	.video-box:hover .video-text{
		background-color: #1e2c5c;
	}
	.video-box:hover .video-text .text1,	.video-box:hover .video-text .text2{
		color: #ffffff;
	}
</style>
