import { render, staticRenderFns } from "./TVPlayer.vue?vue&type=template&id=07aa6696&scoped=true&"
import script from "./TVPlayer.vue?vue&type=script&lang=js&"
export * from "./TVPlayer.vue?vue&type=script&lang=js&"
import style0 from "./TVPlayer.vue?vue&type=style&index=0&id=07aa6696&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07aa6696",
  null
  
)

export default component.exports