import axios from 'axios'

var name = 'https://api.sisaadmin.test.t2.idealead.com'
// var name = 'http://localhost:1337'
export const getPresidentWordsPage = () => {
	 return axios.request({
		method: 'get',
		url:  name + '/api/president-words-pages?filters[language]=en'
	})
}

export const getPresidentWordsPageCN = () => {
	 return axios.request({
		method: 'get',
		url:  name + '/api/president-words-pages?filters[language]=cn'
	})
}

export const getCampusImage = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-images?filters[language]=en'
	})
}

export const getCampusVideo = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-videos?filters[language]=en'
	})
}

export const getCampusImageCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-images?filters[language]=cn'
	})
}

export const getCampusVideoCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-videos?filters[language]=cn'
	})
}

export const getArchitectureTeam = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/architecture-teams?filters[language]=en'
	})
}

export const getArchitectureTeamCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/architecture-teams?filters[language]=cn'
	})
}

export const getcoPages = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/co-pages?filters[language]=en'
	})
}

export const getcoPagesCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/co-pages?filters[language]=cn'
	})
}

export const getSixColleges = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-pages?filters[language]=en'
	})
}

export const getSixCollegesDetail = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-details?filters[language]=en'
	})
}

export const getSixCollegesCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-pages?filters[language]=cn'
	})
}

export const getSixCollegesDetailCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/six-colleges-details?filters[language]=cn'
	})
}

export const getIgAndLevel = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/ig-andlevels?filters[language]=en'
	})
}

export const getIgAndLevelCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/ig-andlevels?filters[language]=cn'
	})
}


export const getCampusItem = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-items?filters[language]=en'
	})
}

export const getCampusList = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-lists?filters[language]=en'
	})
}

export const getCampusItemCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-items?filters[language]=cn'
	})
}

export const getCampusListCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/campus-news-lists?filters[language]=cn'
	})
}

export const getTV = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tv-station-tvs?filters[language]=en'
	})
}

export const gettvinterviews = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tvinterviews?filters[language]=en'
	})
}

export const getrecomdlist= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recomd-lists?filters[language]=en'
	})
}

export const getTVCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tv-station-tvs?filters[language]=cn'
	})
}

export const gettvinterviewsCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/tvinterviews?filters[language]=cn'
	})
}

export const getrecomdlistCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recomd-lists?filters[language]=cn'
	})
}
export const getRulesImage= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-images?filters[language]=en'
	})
}

export const getRulesItem= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-items?filters[language]=en'
	})
}

export const getRulesImageCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-images?filters[language]=cn'
	})
}

export const getRulesItemCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/rules-items?filters[language]=cn'
	})
}

export const getRecruit= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recruits?filters[language]=en'
	})
}
export const getRecruitCN = () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/recruits?filters[language]=cn'
	})
}
export const getViedoData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/viedo-dates?filters[language]=en'
	})
}

export const getViedoDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/viedo-dates?filters[language]=cn'
	})
}

export const getCollegeData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[language]=en'
	})
}

export const getCollegeLimitData= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?pagination[start]=0&pagination[limit]=6'
	})
}

export const getCollegeLimitNextData= (onePage) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?pagination[start]='+ onePage +'&pagination[limit]=6'
	})
}

export const getCollegeLimitNeedData= (major,country,forte) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[country][$contains]='+ country +'&&filters[major][$contains]=' +major+'&&filters[forte][$contains]='+forte
	})
}

export const getCollegeLimitNeedTextData= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[name_en][$contains]='+ needname
	})
}

export const getCollegeDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[language]=cn'
	})
}

export const getCollegeLimitDataCN= () => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?pagination[start]=0&pagination[limit]=6'
	})
}

export const getCollegeLimitNextDataCN= (onePage) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?pagination[start]='+ onePage +'&pagination[limit]=6'
	})
}

export const getCollegeLimitNeedDataCN= (major,country,forte) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[country][$contains]='+ country +'&&filters[major][$contains]=' +major+'&&filters[forte][$contains]='+forte
	})
}

export const getCollegeLimitNeedTextDataCN= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[name][$contains]='+ needname
	})
}

export const getCollegeLimitNeedIDDataCN= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/college-cns?filters[id]='+ needname
	})
}
export const getCollegeLimitNeedIDData= (needname) => {
	 return axios.request({
		method: 'get',
		url: name + '/api/colleges?filters[id]='+ needname
	})
}