<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span  @click="comeMyself(`/planningCN/foreignCommercial`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeIndex">Home</div>
							<div class="item" >
								<p @click="comePresidnt">About Us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">Principal Message</div>
									<div class="drop-down-item" @click="comeintro">Introduction</div>
									<div class="drop-down-item" @click="comeVisit">Campus Tour</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">Structure and Management Team</div>
									<div class="drop-down-item" @click="comeCo">Our Partners</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">Teaching</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL</div>
									<div class="drop-down-item" @click="comeBtec">BTEC</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">News</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">Campus News</div>
									<div class="drop-down-item" @click="comeTVstation">Videos</div>
									<div class="drop-down-item" @click="comeCalendar">Calendar</div>
								</div>
							</div>
							<div class="item active">
								<p @click="comeService">University Counseling</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">University Counseling</div>
									<div class="drop-down-item" @click="comeCollege">Foreign art schools</div>
									<div class="drop-down-item">Foreign Business Colleges</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeStory">Admissions</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">SISA Story</div>
									<div class="drop-down-item" @click="comeRules">Admissions Brochure</div>
									<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">New Student Scholarship</div>
									<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/information/apply`)">Online Registration</div>
									<div class="drop-down-item" @click="comeMyself(`/information/message`)">Message</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contact/Consultation`)"> Contact us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contact/Consultation`)">Contact</div>
									<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">Recruitment</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/planningbg.c0f4821.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p @click="comeService">University Counseling</p>
						<p @click="comeCollege">Foreign art schools</p>
						<p class="nav-active">Foreign Business Colleges</p>
					</div>
					<div style="display: none;">
						<p @click="comeCampusNews" >校园动态</p>
						<p class="nav-active">校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p @click="comeTeaching" >SISA艺术学院</p>
						<p @click="comeCommercial">SIBC商学院</p>
						<p @click="comeIgandAlevel">IGCSE、A-level学制</p>
						<p class="nav-active">BTEC学制</p>
						<!-- <p >辰美合作单位</p> -->
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					<div style="display: none;">
						<p class="">辰美故事</p>
						<p>招生简章</p>
						<p>新生奖学金</p>
						<p>Q&A</p>
						<p>在线报名</p>
						<p>留言咨询</p>
					</div>
					
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<p class="title50">国外商科名校简介 </p>
						<p class="title50 bottom96">Introduction of the overseas business schools</p>
						<p class="text18R">对于全世界的商科院校而言，目前国际上存在三大权威认证，分别是国际商学院协会AACSB、欧洲管理发展基金会EFMD和工商管理硕士协会AMBA。通过认证就意味着学校项目适时更新，符合商界需求，也意味着优秀的师资力量和学位的质量保证。</p>
						<p class="title30 title-lineS bottom56">英国23家获得“三大权威认证”的商学院（排名不分先后）</p>
						<div class="flex">
							<p class="text18R flex1">
								
								1.伦敦商学院
								<br>
								
								2.伦敦城市大学卡斯商学院
								<br>
								
								3.帝国理工大学商学院
								<br>
								
								4.华威大学商学院
								<br>
								
								5.克兰菲尔德大学管理学院
								<br>
								
								6.思克莱德大学商学院
								<br>
								
								7.阿什里奇商学院
								<br>
								
								8.阿斯顿大学商学院
								<br>
								
								9.伯明翰大学商学院
								<br>
								
								10.布拉福德大学管理学院
								<br>
								
								11.杜伦大学商学院
								<br>
								
								12.爱丁堡大学商学院
								<br>
							</p>
							<p class="text18R flex1">

								13.格拉斯哥大学亚当斯密商学院
								<br>
								
								14.雷丁大学亨利商学院
								<br>
								
								15.兰卡斯特大学管理学院
								<br>
								
								16.拉夫堡大学商学院
								<br>
								
								17.曼彻斯特大学商学院
								<br>
								
								18.利兹大学商学院
								<br>
								
								19.谢菲尔德大学管理学院
								<br>
								
								20.赫尔大学商学院
								<br>

								21.巴斯大学管理学院
								<br>
								
								22.欧洲商学院（伦敦分校）
								<br>
								
								23.纽卡斯尔大学商学院
								<br>

							</p>
						</div>
						<p class="title30 title-lineS bottom56">美国知名商科院校介绍</p>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img1.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">1、沃顿商学院@宾夕法尼亚大学</p>
								<p class="text18R">宾夕法尼亚大学的沃顿商学院是世界知名的商学院，创立以来精英辈出。知名校友有美国第45任总统唐纳德·特朗普，全球著名的投资商巴菲特，SpaceX创始人埃隆·马斯克。本科生可以在宾夕法尼亚大学的任何一所本科学院和八所研究生学院上课。学校拥有300多个宾夕法尼亚大学俱乐部和45个沃顿商学院俱乐部，你会在那里结交一辈子的朋友。沃顿商学院有很多课程和研究领域可供选择，有10个不同的院系，超过17个专业。</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img2.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">2、斯隆管理学院@麻省理工学院</p>
								<p class="text18R">麻省理工学院斯隆管理学院的使命是培养有原则、有创新精神的领导者，改善世界，并提出促进管理实践的想法。课程的重点是行动学习，要求学生将课堂上学到的概念应用到现实世界的商业环境中。按照商学院的标准，课程的学术水平被认为是极其苛刻的，比大多数课程更强调分析推理和定量分析。</p>
							</div>
						</div>
						<div class="item-box flex">
							<img src="../../../public/static/img/foreignCommercial-img3.png" class="img1" alt="">
							<div class="item-right">
								<p class="title22">3、斯特恩商学院@纽约大学</p>
								<p class="text18R">斯特恩学院提供市场营销、金融、信息系统、精算学、经济政策、经济理论、娱乐媒体与技术、会计学(注册会计师和普通会计)等专业，以及国际商务、金融系统、可持续商业和娱乐、媒体和技术证书课程。斯特恩还提供自己的海外留学项目IBEX(国际商务交流项目)。该项目在世界各地的许多商学院持续一个学期。斯特恩商学院目前在新加坡、澳大利亚、中国、丹麦、英国、法国、香港特别行政区、意大利、韩国、墨西哥、荷兰、西班牙和泰国设有多个合作学校。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'foreignCommercial',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
			}
		},
		
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/index");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 7.66rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.bottom96 {
		margin-bottom: .96rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.bottom56 {
		margin-bottom: .56rem;
	}
	.title-lineS {
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.flex {
	  display: flex;
	}
	.flex1, .item-right {
	    flex: 1;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.item-box{
	    margin-bottom: .59rem;
	}
	.img1 {
	    width: 3.29rem;
	    height: 2.6rem;
	    margin-right: .15rem;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
</style>
