<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span class="active">•&nbsp;中文</span>&nbsp;/&nbsp;<span  @click="comeMyself(`/about/visitCampus`)">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeMyself(`/indexCN`)">首页</div>
							<div class="item active" >
								<p @click="comePresidnt">关于辰美</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">校长寄语</div>
									<div class="drop-down-item" @click="comeintro">辰美介绍</div>
									<div class="drop-down-item" @click="comeVisit">参观校园</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">辰美架构及管理团队</div>
									<div class="drop-down-item" @click="comeCo">辰美合作单位</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">教学体系</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA艺术学院</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC商学院</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL学制</div>
									<div class="drop-down-item" @click="comeBtec">BTEC学制</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">最新资讯</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">校园动态</div>
									<div class="drop-down-item" @click="comeTVstation">校园原创视频</div>
									<div class="drop-down-item" @click="comeCalendar">校历</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">升学规划</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">升学服务</div>
									<div class="drop-down-item" @click="comeCollege">国外艺术院校</div>
									<div class="drop-down-item" @click="comeForeignCommercial">国外商科院校</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeStory">招生信息</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">辰美故事</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/rules`)">招生简章</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/scholarship`)">新生奖学金</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/apply`)">在线报名</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/message`)">留言咨询</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contactCN/Consultation`)">联系我们</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contactCN/Consultation`)">联系及咨询方式</div>
									<div class="drop-down-item" @click="comeMyself(`/contactCN/recruit`)">招聘信息</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/aboutCMbg.8d6047f.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p @click="comePresidnt">校长寄语</p>
						<p @click="comeintro()">辰美介绍</p>
						<p class="nav-active"> 参观校园</p>
						<p @click="comeArchitectureAndTeam">辰美架构及管理团队</p>
						<p @click="comeCo">辰美合作单位</p>
						
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					<div style="display: none;">
						<p class="">辰美故事</p>
						<p>招生简章</p>
						<p>新生奖学金</p>
						<p>Q&A</p>
						<p>在线报名</p>
						<p>留言咨询</p>
					</div>
					<div style="display: none;">
						<p class="">校园动态</p>
						<p>校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					<div style="display: none;">
						<p class="">升学服务</p>
						<p>国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<p class="title">校园图片参观 <br> VIRTUAL CAMPUS TOUR</p>
						<div class="img-box">
							<div :class="img.attributes.isShow==0 ? 'miss':''" class="hiddenImg" v-for="(img,i) in imageList">
								<figure @click="showImagePreview(img.attributes.image)" tabindex="0" class=" visitCampus-c4" :class="img.attributes.flag==3 ?  'visitCampus-img':''||img.attributes.flag==1 ? 'visitCampus-img1 ':''|| img.attributes.flag==2 ? 'visitCampus-img2 ':''"  >
									
									<img  :src="img.attributes.image" alt="">
									<figcaption>
										<div class="c4-reveal-up">
											<p class="c4-reveal-up-text">{{img.attributes.title}}</p>
										</div>
									</figcaption>
								</figure>
							</div>

						</div>
						<div class="data-more" @click="showYouMore" v-if="moreShow">
							<span>查看更多</span>
							<img src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<div class="data-more" @click="showYouMore" v-if="!moreShow">
							<span>收起</span>
							<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<p class="title2">校园视频观看</p>
						<div class="video-list">
							<div class="video-look" @click="comeUrl(video.attributes.url)" :class="video.attributes.isShow==0 ? 'miss':''"  v-for="(video,i) in videoList">
								<div class="video-box">
									<div class="video-img">
										<!-- 1 -->
										<img class="img1" :src="video.attributes.image" alt="">
										<div class="player-bg">
											
										</div>
										<img class="icon-player" src="../../../public/static/img/icon-player.png" alt="">
									</div>
									<div class="video-text">
										<div class="line">
											<img class="title-icon" src="../../../public/static/img/视频主题icon.png" alt="">
											<!-- 3 -->
											<p class="text1">{{video.attributes.title}}</p>
											
										</div>
										<p class="text2">{{video.attributes.description}}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="data-more" @click="showVideoMore" v-if="moreVideoShow">
							<span>查看更多</span>
							<img src="../../../public/static/img/查看更多.png" alt="">
						</div>
						<div class="data-more" @click="showVideoMore" v-if="!moreVideoShow">
							<span>收起</span>
							<img class="down-img" src="../../../public/static/img/查看更多.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	import {  getCampusImageCN,getCampusVideoCN } from "../../api/api.js"
	export default{
		name:'visitCampus',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				moreShow:true,
				showMore:false,
				detailShow:false,
				imageList:[],
				videoList:[],
				
				videoShow:false,
				moreVideoShow:true,
			}
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			this.getImage()
			this.getVideo()
			this.addAnimation()
			this.hiddenVideo()
		},
		methods:{
			getImage(){
				getCampusImageCN().then(res=>{
	
					this.imageList = res.data.data
					console.log(this.imageList,'da')
				})
			},
			comeUrl(e){
				window.location.href = e
			},
			getVideo(){
				getCampusVideoCN().then(res=>{
					console.log(res.data.data)
					this.videoList = res.data.data
				})
			},
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/informationCN/rules");
			},
			comeStory(){
				this.$router.push("/informationCN/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planningCN/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planningCN/College");
			},
			comeService(){
				this.$router.push("/planningCN/service");
			},
			comeCalendar(){
				this.$router.push("/lifeCN/calendar");
			},	
			comeTVstation(){
				this.$router.push("/lifeCN/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/lifeCN/campusNews");
			},
			comeBtec(){
				this.$router.push("/teachingCN/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teachingCN/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teachingCN/commercial");
			},
			comeTeaching(){
				this.$router.push("/teachingCN/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/aboutCN/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/aboutCN/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/aboutCN/introduction");
			},
			comeVisit(){
				this.$router.push("/aboutCN/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/aboutCN/presidentWords");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			showImagePreview(img){
				console.log(1)
				const image = new Image();
				image.src = img;
				image.onload = () => {
					// 创建弹出层
				const previewContainer = document.createElement('div');
				previewContainer.style.position = 'fixed';
				previewContainer.style.top = 0;
				previewContainer.style.zIndex=10000;
				previewContainer.style.bottom = 0;
				previewContainer.style.left = 0;
				previewContainer.style.right = 0;
				previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
				previewContainer.style.display = 'flex';
				previewContainer.style.justifyContent = 'center';
				previewContainer.style.alignItems = 'center';
				document.body.appendChild(previewContainer);
				// 在弹出层中添加图片
				const previewImage = document.createElement('img');
				previewImage.src =img;
				previewImage.style.maxWidth = '50%';
				previewImage.style.maxHeight = '50%';
				previewContainer.appendChild(previewImage);
				// 点击弹出层，关闭预览
				previewContainer.addEventListener('click', () => {
					document.body.removeChild(previewContainer);
				});
				};
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
					let osTop =
							document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
							osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
							clearInterval(timer);
					}
			}, 30);         
			},
			addAnimation(){
				var lis = document.querySelectorAll(".visitCampus-c4");
				// var list = document.querySelector(".visitCampus-c4").querySelectorAll(".c4-reveal-up-text");
				console.log(lis)
				lis.forEach(item => {
				    item.onmouseover = () => {
				       //先全部清空选中，再给当前添加选中
							item.children[1].children[0].children[0].classList.remove('c4-reveal-up-text1')
				      item.children[1].children[0].children[0].classList.add('c4-reveal-up-text');
				    }
				})
				lis.forEach(item => {
				    item.onmouseleave = () => {
				       //先全部清空选中，再给当前添加选中
				       item.children[1].children[0].children[0].classList.remove('c4-reveal-up-text')
								console.log(item.children[1].children[0].children)
				       item.children[1].children[0].children[0].classList.add('c4-reveal-up-text1');
				    }
				})
			},
			
			showYouMore(){
				
				if(this.moreShow){
					this.moreShow = false
					this.showMore = true
					var lis = document.querySelectorAll(".hiddenImg");
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.remove('miss')
						}
					}
					
				}else{
					this.moreShow = true
					this.showMore = false
					var lis = document.querySelectorAll(".hiddenImg");
					for(var i = 0;i<=lis.length-1;i++){
						if(i>4){
							lis[i].classList.add('miss')
						}
					}
				}
			},
			hiddenVideo(){
				var lis = document.querySelectorAll(".video-look");
				console.log(lis[1])
				for(var i = 0;i<=lis.length-1;i++){
					if(i>3){
						lis[i].classList.add('miss')
					}
				}
			},
			showVideoMore(){
				if(this.moreVideoShow){
					this.moreVideoShow = false
					var lis = document.querySelectorAll(".video-look");
					console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>3){
							lis[i].classList.remove('miss')
						}
					}
					// this.videoShow = true
				}else{
					this.moreVideoShow = true
					var lis = document.querySelectorAll(".video-look");
					console.log(lis[1])
					for(var i = 0;i<=lis.length-1;i++){
						if(i>3){
							lis[i].classList.add('miss')
						}
					}
				}
			}
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 6.34rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.title{
		font-size: .4rem;
		line-height: .4rem;
		margin-bottom: .54rem;
		font-weight: 700;
		color: #1e2c5c;
	}
	.img-box{
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
	}
	.visitCampus-img{
		width: 9.56rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-img1{
		width: 5.58rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-img2{
		width: 3.82rem;
		height: 2.98rem;
		margin-bottom: .16rem;
		background-color: rgba(30,44,92,.7);
		cursor: pointer;
	}
	.visitCampus-c4{
		--text-color: #fff;
		--primary-color: #00b4db;
		--secondary-color: #0083b0;
		--padding: 1em;
		--transition-duration: 600ms;
		--border-margin: 15px;
		--border-width: 3px;
		--border-color: #fff;
		--border-radius: 3px;
		--image-opacity: 0.25;
		display: inline-flex;
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		margin: 0;
		padding: 0;
		color: #fff;
		color: var(--text-color);
		border-radius: 3px;
		border-radius: var(--border-radius);
	}
	.visitCampus-c4 img{
		object-fit: cover;
		max-width: 100%;
		width: 100%;
		height: 100%;
	}
	.visitCampus-c4 figcaption{
		/* padding: calc(var(--padding)*2); */
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 0.5em;
		/* padding: calc(var(--padding)); */
		/* width: 100%; */
		z-index: 1;
	}
	.visitCampus-c4:hover figcaption{
		background-color: rgb(122,129,157,.7);
		transition: 1s;
	}
	.c4-reveal-up-text{
		margin-top: 1rem;
		font-size: .25rem;
		font-weight: 700;
		color: #fff;
		opacity: 0;
		text-align: center;
		transition: all 1s;
	}
	.c4-reveal-up-text1{
		margin-top: 1rem;
		font-size: .25rem;
		font-weight: 700;
		color: #fff;
		opacity: 0;
		text-align: center;
		animation: smallbag 0.5s linear forwards;
	}
	.visitCampus-c4:hover .c4-reveal-up-text{
		animation: bigbag 0.5s linear forwards;
	}
	.data-more{
		cursor: pointer;
		height: .24rem;
		margin-top: .11rem;
		align-items: center;
		justify-content: center;
		display: flex;
	}
	.data-more span{
		font-size: .16rem;
		font-weight: 500;
		color: #d7a16f;
		line-height: .24rem;
		margin-right: .05rem;
		
	}
	.down-img{
		transform: rotate(180deg);
	}
	.title2{
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-top: .58rem;
		margin-bottom: .42rem;
	}
	.video-list{
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
	}
	.video-box{
		width: 2.26rem;
		height: 2.32rem;
		overflow: hidden;
		border-radius: 0 0 .2rem 0;
		cursor: pointer;
		margin-bottom: .2rem;
	}
	.video-img{
		position: relative;
	}
	.video-img .img1{
		display: flex;
	}
	.player-bg{
		position: absolute;
		left: 0;
		top: 0;
		width: 2.26rem;
		height: 1.34rem;
		background-color: rgba(0,0,0,.6);
		z-index: 2;
	}
	.icon-player{
		position: absolute;
		left: 0;
		top: 0;
		width: .54rem;
		height: .54rem;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 3;
	}
	.video-img,.video-img .img1{
		width: 2.26rem;
		height: 1.34rem;
	}
	.video-text{
		width: 100%;
		height: .98rem;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.line{
		height: .39rem;
		border-bottom: 1px solid #bfbfbf;
		align-items: center;
		display: flex;
	}
	.title-icon{
		width: .09rem;
		height: .14rem;
		display: flex;
		margin-left: .12rem;
		margin-right: .07rem;
	}
	.video-box .text1,.video-box .text2{
		color: #1e2c5c;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.miss{
		display: none !important;
	}
	.video-box .text1{
		flex: 1;
		margin-right: .12rem;
		font-size: .15rem;
		font-weight: 700;
		line-height: .39rem;
		white-space: nowrap;
	}
	.video-box .text2{
		font-size: 12px;
		font-weight: 400;
		line-height: .21rem;
		width: 1.71rem;
		margin-left: .27rem;
		margin-top: .1rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.video-box:hover .video-text{
		background-color: #1e2c5c;
	}
	.video-box:hover .text1{
		color: #ffffff;
	}
	.video-box:hover .text2{
		color: #ffffff;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	@keyframes bigbag {
	  0% {
			opacity: 0.1;
	    margin-top: 0.8rem;
	  }
		10%{
			opacity: 0.2;
			margin-top: 0.8rem;
		}
		20%{
			opacity: 0.3;
			margin-top: 0.8rem;
		}
		30%{
			opacity: 0.4;
			margin-top: 0.7rem;
		}
	  40% {
			opacity: 0.5;
	    margin-top: 0.6rem;
	  }
		50%{
			opacity: 0.6;
			margin-top: 0.5rem;
		}
		60%{
			opacity: 0.7;
			margin-top: 0.4rem;
		}
		70%{
			opacity: 0.8;
			margin-top: 0.3rem;
		}
		80%{
			opacity: 0.9;
			margin-top: 0.2rem;
		}
		90%{
			opacity: 1;
			margin-top: 0.1rem;
		}
	  100% {
			opacity: 1;
			margin-top: 0rem;
	  }
	}
	@keyframes smallbag {
	  0% {
			opacity: 1;
	    margin-top: 0rem;
	  }
		10%{
			opacity: 0.9;
			margin-top: 0.1rem;
		}
	  20% {
			opacity: 0.8;
	    margin-top: 0.2rem;
	  }
		30%{
			opacity: 0.7;
			margin-top: 0.3rem;
		}
		40%{
			opacity: 0.6;
			margin-top: 0.4rem;
		}
	  50% {
			opacity: 0.5;
			margin-top: 0.5rem;
	  }
		60%{
			opacity: 0.4;
			margin-top: 0.6rem;
		}
		70%{
			opacity: 0.3;
			margin-top: 0.7rem;
		}
		80%{
			opacity: 0.2;
			margin-top: 0.8rem;
		}
		90%{
			opacity: 0.1;
			margin-top: 0.8rem;
		}
		100%{
			opacity: 0;
			margin-top: 0.8rem;
		}
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
</style>
