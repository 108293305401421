<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span class="active">•&nbsp;中文</span>&nbsp;/&nbsp;<span @click="comeMyself(`/information/qanda`)">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeMyself(`/indexCN`)">首页</div>
							<div class="item" >
								<p @click="comePresidnt">关于辰美</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">校长寄语</div>
									<div class="drop-down-item" @click="comeintro">辰美介绍</div>
									<div class="drop-down-item" @click="comeVisit">参观校园</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">辰美架构及管理团队</div>
									<div class="drop-down-item" @click="comeCo">辰美合作单位</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">教学体系</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA艺术学院</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC商学院</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL学制</div>
									<div class="drop-down-item" @click="comeBtec">BTEC学制</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">最新资讯</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">校园动态</div>
									<div class="drop-down-item" @click="comeTVstation">校园原创视频</div>
									<div class="drop-down-item" @click="comeCalendar">校历</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">升学规划</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">升学服务</div>
									<div class="drop-down-item" @click="comeCollege">国外艺术院校</div>
									<div class="drop-down-item" @click="comeForeignCommercial">国外商科院校</div>
								</div>
							</div>
							<div class="item active">
								<p @click="comeStory">招生信息</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">辰美故事</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/rules`)">招生简章</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/scholarship`)">新生奖学金</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/apply`)">在线报名</div>
									<div class="drop-down-item" @click="comeMyself(`/informationCN/message`)">留言咨询</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contactCN/Consultation`)">联系我们</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contactCN/Consultation`)">联系及咨询方式</div>
									<div class="drop-down-item" @click="comeMyself(`/contactCN/recruit`)">招聘信息</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/recruitbg.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p @click="comeStory">辰美故事</p>
						<p @click="comeMyself(`/informationCN/rules`)">招生简章</p>
						<p @click="comeMyself(`/informationCN/scholarship`)">新生奖学金</p>
						<p class="nav-active">Q&A</p>
						<p @click="comeMyself(`/informationCN/apply`)">在线报名</p>
						<p @click="comeMyself(`/informationCN/message`)">留言咨询</p>
					</div>
					<div style="display: none;">
						<p >升学服务</p>
						<p class="nav-active">国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p @click="comeCampusNews" >校园动态</p>
						<p class="nav-active">校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p @click="comeTeaching" >SISA艺术学院</p>
						<p @click="comeCommercial">SIBC商学院</p>
						<p @click="comeIgandAlevel">IGCSE、A-level学制</p>
						<p class="nav-active">BTEC学制</p>
						<!-- <p >辰美合作单位</p> -->
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					
					
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<p class="title50">为何选择辰美？</p>
						<p class="title50">我们的完美答案</p>
						<img src="../../../public/static/img/QA-img1.jpg" class="img" alt="">
						<p class="text18R">
							
							在这个多彩的世界里，艺术与设计是无所不在的。但我们闭上双眼，然后再次张开，我们眼前的一切都是艺术与设计。我们身处的温暖家居，我们身穿的漂亮衣服，我们手上的酷炫杯子，我们无法离手的电子产品，我们周围的高楼大厦、我们触目所及的一切物品……这些都是艺术和设计无处不在的呈现。
							<br>
							我们可以帮助您更好地面对这个变幻莫测的世界，为您未知但令人兴奋的未来做好充足准备；我们可以帮助您实现艺术梦想，让您站在世界的艺术舞台上灿若星辰！
							<br>
							因为——
							<br>
							A、我们是陪伴学生价值观养成的人生导师
							<br>
							B、我们是拥有丰富行业资源的艺术平台
							<br>
							C、我们是权威国际艺术课程与复合型校本课程完美结合的艺术学院
							<br>
							D、我们是大湾区标杆性的城市校园
							<br>
							E、我们是国际课程考试和竞赛的考点学校
							<br>
							F、我们是全球顶尖艺术院校招生点
							<br>
							G、我们是国际文化艺术交流基地
        
						</p>
						
						<p class="title30">关于辰美、关于艺术教育，或许你还有如下疑问：</p>
						<div class="title-line">
							
						</div>
						<p class="title22">1、辰美艺术教育是一所什么样的学校？</p>
						<p class="text18R">
							答： 辰美艺术教育是佛山市桂城政府引进、香港基汇资本斥资4000万美元打造的优质国际化艺术教育平台，是一所全日制国际艺术高中和国际艺术预科学校。
							<br>
							<br>
						</p>
						<p class="title22">2、学校在哪里？</p>
						<p class="text18R">
							
							答：学校位于广州南站商圈——佛山市南海区三山新城。
							<br>
							辰美校区交通便利，与广州南站的直线距离只有2公里，10分钟车程，还有学校附近年底就会通车的轻轨。对于学生和家长来说，都是非常便利的。
          
							<br>
							<br>
						</p>
						<p class="title22">3、听说辰美是一个城市校园，跟传统学校会不一样？</p>
						<p class="text18R">
							
							答：辰美拥有全国独一无二的城市艺术校园，教学大楼面积达20000多平方米。我们学校一共有6层，每一层的设计都不一样，都是独一无二的。我们的学校中间是中空的，并且有垂直立体的绿化，雨水和阳光都会直接穿透进来。
							<br>
							我们有非常齐全的专业场室和运动休闲空间。
							<br>
							在辰美学习，一定是非常幸福的。
          
							<br>
							<br>
						</p>
						<p class="title22">4、听说辰美是学院制，能给我们介绍一下吗？</p>
						<p class="text18R">

							答：辰美艺术教育设有六大学院，有时尚学院、纯艺学院、传媒学院、立体设计学院、音乐学院、影视表演学院，囊括了像服装、舞蹈、表演等等艺术专业，覆盖了艺术留学的所有专业。
							<br>
							在中世纪时期，英国就常常采用学院制作为学校的运行管理模式，现在英国最著名的三所学院制学校是牛津大学、剑桥大学和杜伦大学，被大家统称为Doxbridge。
							<br>
							学院制与普通学校的区别在于学院（college）并不是按专业（school / department）划分，而是由多个专业组成的一个个兼容并包的多元社区，一个学院里有不同年级不同学科的学生，是学生形成强烈荣誉感的小型学术圈和大家庭。
          
							<br>
							<br>
						</p>
						<p class="title22">5、辰美需要中考成绩吗？是怎么分班的呢？大概多少人一个班？</p>
						<p class="text18R">
							
							答：不需要的。辰美更看重学生的艺术热情、创意思维和综合素养。我们的入学考试，主要有艺术素养测试、音乐素养测试和中英文面试。我们首届学生招收120学生，择优录取，招完即止。
							<br>
							辰美采用的是小班制模式，高一高二的班级，每个班都不超过20人，高三的班级，不超过10人。辰美力求给每一个辰美孩子最贴心的陪伴和最专业的指导。
          
							<br>
							<br>
						</p>
						<p class="title22">6、辰美学生的生活环境怎么样呢？</p>
						<p class="text18R">
							
							答：辰美作为一所艺术学校，尤其重视环境氛围对学生的熏陶。辰美投资公司香港基汇资本专门为辰美学子建造一栋充满艺术特色的希尔顿酒店。在星级酒店般的宿舍生活，辰美的孩子们一定是幸福的，自信的，饱满的。
          
							<br>
							<br>
						</p>
						<p class="title22">7、辰美艺术教育主要开设什么方向的专业课程？</p>
						<p class="text18R">
							
							答：辰美艺术教育是全国第一所同时开设艺术设计、音乐、电影三大专业。
							<br>
							我们所开设的艺术专业可以说是涵盖了艺术升学的所有细分专业，可以满足不同孩子的艺术学习需求。
          
							<br>
							<br>
						</p>
						<p class="title22">8、一所好的学校，一定是要有好的老师，不知道辰美的师资如何呢？</p>
						<p class="text18R">
							
							答：辰美拥有国际一流的教师团队。现在我们的老师，有在英女王御前表演的艺术家、音乐博士，有全球最Top艺术大学海归，有牛剑藤校的高材生。我们的中教外教比例为1:1，师生比例为1:6。我们的老师一定能给到孩子最贴心最专业的指导，助力他们走向世界舞台，灿若星辰。
         
							<br>
							<br>
						</p>
						<p class="title22">9、在辰美读书，还需要额外去机构补课吗？</p>
						<p class="text18R">
							
							答：在辰美，是可以一站式解决艺术升学所有要求，艺术升学所需要的文化课成绩，艺术作品集，国际语言成绩和升学指导，都将在辰美完成，不需要再到任何其他地方做任何补充，省时省力省钱。
							<br>
							辰美的学生，升学时一定能拿到5份以上世界前三十艺术院校的Offer。因为，好的课程，好的教师团队，加上孩子的努力，一定会有好的升学。
          
							<br>
							<br>
						</p>
						<p class="title22">10、我是国内的艺考生，为什么要选择辰美、选择国际艺术学院？</p>
						<p class="text18R">
							
							答：参加国内艺考和选择艺术留学都是实现艺术梦想的途径。国内艺考竞争激烈，2019年全国100万艺术考生争夺的是九大美院、十大音乐学院和表演学院的少数席位，录取率在1%-5%之间。而海外艺术院校的选择多，录取率高，世界前三十艺术院校平均录取率高达60%。选择辰美，选择国际艺术教育，更有机会入读世界艺术名校。
          
							<br>
							<br>
						</p>
						<p class="title22">11、我没有艺术基础，我是否也可以选择辰美？</p>
						<p class="text18R">
							
							答：当然可以。辰美的课程采用的是英国高中课程和艺术预科课程，通过项目式教学，让学生从基础的理论知识开始，一步步培养学生的艺术技巧和思维能力，零基础的学生也可以完全胜任。另外，辰美还可以为有更高追求的学生提供VIP课程培训，1对1个性化指导，针对学生各个能力模块进行训练，提高TOP3艺术院校的录取率。
          
							<br>
							<br>
						</p>
						<p class="title22">12、国内也有九大美院和著名的音乐学院，为什么要选择海外艺术院校？</p>
						<p class="text18R">
							
							答：
							<br>
							①在录取率方面，相比起国内的九大美院和音乐学院，海外艺术院校的院校选择多、专业细分度高、录取率高，在升学的成功率上比较有保障；
							<br>
							②海外艺术院校的录取更关注个人作品集，你的作品集是独一无二的，专注于表达个人的艺术兴趣、艺术理解、艺术素养和艺术创造力，你无需与数十万艺术考生拼技法，更无需辗转各地参加不同院校的艺术考试。你只需要做最好的自己；
							<br>
							③在教育资源方面，海外艺术院校先进的教学理念和方法，对学生思维的开发和动手实践能力非常注重，能为学生提供更多全球实习机会和就业机会；
							<br>
							④在就业方面，每个海外院校都设置专门的就业中心，帮助学生从入学时就开始规划就业路线，协助学生选择实习机会和求职活动，使学生毕业后的就业率更有保障。
          
							<br>
							<br>
						</p>
						<p class="title22">13、辰美的学费是多少一年呢？</p>
						<p class="text18R">
							
							答：辰美的学费是188000元一年。
							<br>
							广州深圳等地的国际学校，很多学费都在20万到30万的区间，再考虑艺术学习所需要的专业场室，设备和耗材，我们这个学费可以说是不高的。但我们愿意用一个更实惠的价格，向家长朋友们提供一个更优质更专业的国际艺术教育。
							<br>
							<br>
						</p>
						<p class="title22">14、辰美有奖学金吗?</p>
						<p class="text18R">
							
							答：辰美给所有对艺术学习有热情和天赋的孩子，准备过百万的奖学金。辰美艺术教育致力于打造一个世界级的国际艺术平台，让您的孩子前瞻未来，超群出众。
          
							<br>
							<br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'qanda',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
			}
		},
		
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/informationCN/rules");
			},
			comeStory(){
				this.$router.push("/informationCN/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planningCN/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planningCN/College");
			},
			comeService(){
				this.$router.push("/planningCN/service");
			},
			comeCalendar(){
				this.$router.push("/lifeCN/calendar");
			},	
			comeTVstation(){
				this.$router.push("/lifeCN/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/lifeCN/campusNews");
			},
			comeBtec(){
				this.$router.push("/teachingCN/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teachingCN/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teachingCN/commercial");
			},
			comeTeaching(){
				this.$router.push("/teachingCN/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/aboutCN/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/aboutCN/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/aboutCN/introduction");
			},
			comeVisit(){
				this.$router.push("/aboutCN/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/aboutCN/presidentWords");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 6.34rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.title50{
		line-height: .72rem;
		margin-bottom: 0;
	}
	.img{
		width: 100%;
		height: auto;
		margin: .6rem 0;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.title30, .title30M {
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
	}
	.title30, .title40 {
		font-weight: 700;
		color: #0e183f;
	}
	.title-line {
		width: 100%;
		height: 1px;
		background-color: #0f193e;
	}
	.title-line {
		margin: .1rem 0 .32rem;
		background-color: rgba(0,0,0,.5);
		height: 1px;
	}
	.title22 {
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
</style>
