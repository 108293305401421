<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span  @click="comeMyself(`/indexCN`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class="active item item1">Home</div>
							<div class="item" >
								<p @click="comePresidnt">About Us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">Principal Message</div>
									<div class="drop-down-item" @click="comeintro">Introduction</div>
									<div class="drop-down-item" @click="comeVisit">Campus Tour</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">Structure and Management Team</div>
									<div class="drop-down-item" @click="comeCo">Our Partners</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">Teaching</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL</div>
									<div class="drop-down-item" @click="comeBtec">BTEC</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">News</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">Campus News</div>
									<div class="drop-down-item" @click="comeTVstation">Videos</div>
									<div class="drop-down-item" @click="comeCalendar">Calendar</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">University Counseling</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">University Counseling</div>
									<div class="drop-down-item" @click="comeCollege">Foreign art schools</div>
									<div class="drop-down-item" @click="comeForeignCommercial">Foreign Business Colleges</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeStory">Admissions</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">SISA Story</div>
									<div class="drop-down-item" @click="comeMyself(`/information/rules`)">Admissions Brochure</div>
									<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">New Student Scholarship</div>
									<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/information/apply`)">Online Registration</div>
									<div class="drop-down-item" @click="comeMyself(`/information/message`)">Message</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contact/Consultation`)">Contact us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contact/Consultation`)">Contact</div>
									<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">Recruitment</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="middle">
			<div class="banner w">
				<div class="swiper mySwiper">
				      <div class="swiper-wrapper">
				        <div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a  ><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/f377ca69dad9be57d8310ad5aba5bfd1.jpg" alt=""></a>
								</div>
				        <div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/300dea5ebb422628d25c9fb6739d0d4a.jpg" alt=""></a>
								</div>
				        <div @click="comeMyself(`/about/presidentWords`)" class="swiper-slide">
									<a href=""><img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200731/d41d641e9eb77bb5c3ebd856087eadd2.jpg" alt=""></a>
								</div>
				      </div>
				      <div class="swiper-button-next"></div>
				      <div class="swiper-button-prev"></div>
							 <!-- <div class="swiper-pagination"></div> -->
				    </div>
			</div>
			<div class="info">
				<div class="w info-item">
					<div class="school-introduce">学校介绍</div>
					<div class="school-img"><img src="../../../public/static/img/item01-img3.png" alt=""></div>
					<div class="school-pic"><img src="../../../public/static/img/item01-img2.jpg" alt=""></div>
					<div class="school-icon"><img src="../../../public/static/img/item01-img1.png" alt=""></div>
					<div class="school-txt">
          
          Stellart International School for the Arts (SISA) is dedicated to developing the next generation of designers, entrepreneurs, innovators and other artists – people who strive to bring beauty and harmony to our ever-changing technological world. We believe that Artists learn best by working collaboratively with others, and thus our students will be challenged by our UK curriculum to design, create and solve problems together, whilst developing an appreciation the arts within and outside of China. What is most exciting is that we have partnered with several of the world’s leading universities and artists to enrich our programmes and to provide truly life-changing transdisciplinary opportunities for our students, the majority who board on campus. If you share the same passion for the arts and education as we do, then come and join our community.    </div>
				</div>
				<div class="school-team w">
					<div class="team-title">
						<div class="title-img">
							<img src="../../../public/static/img/teacherteam.png" alt="">
						</div>
						<div class="team-txt">
							教师团队
						</div>
					</div>
					<div class="teacher">
						
						  <!-- Swiper -->
						  <div class="swiper-container">
						    <div class="swiper-wrapper">
						      <div class="swiper-slide ">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/5eb46a467ed1daa6e3a1538ca51a9843.png" alt="">
											</div>
											<p class="teacher-name">
												Ellen Huang
											</p>
											<p class="teacher-work">
												Chairman of the school committee
											</p>
											<p class="describe">
												（网络翻译 web translate）Chairman of the school committee, vice-chairman of Guangdong International Education Promotion Association, director of Guangdong International Art Education Professional Committee, winner of the Gold Award for Sino-British Inter-School Exchange in South China issued by the British Education and Culture Office, and has participated in founding four famous schools, including Nanhai Experimental Middle School, Nanhai Foreign Language School, etc.
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/767451d11d9f78893d8b03b71bd3750a.png" alt="">
											</div>
											<p class="teacher-name">
												Grace Chen 
											</p>
											<p class="teacher-work">
												Honorary principal
											</p>
											<p class="describe">
												（网络翻译 web translate）Special Principal, National Model Principal of Management Innovation, Principal of the Most Influential International School in the Country in 2018, Deputy Director of the International School Special Committee of Guangdong Study Abroad Service Association, Adjunct Professor of Teacher Education Department of South China Normal University, founder of the Foreign Language School Affiliated to South China Normal University school principal.
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/4ec084b79d36782bc1058975a7d4b9c2.png" alt="">
											</div>
											<p class="teacher-name">
												Roy White
											</p>
											<p class="teacher-work">
												Principal Consultant
											</p>
											<p class="describe">
											（网络翻译 web translate）Member of the Council of International Schools (CIS), formerly served in the British Schools Foundation, the founding principal of a famous international school in Hong Kong, China, has experience as principal of international schools in the United Kingdom, the Netherlands, Northern Europe and other countries. CIS highly appreciates the innovative vision of each school he leads.
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/8263460627f530a84488dbc5eed8deab.png" alt="">
											</div>
											<p class="teacher-name">
												Howard Zhang
											</p>
											<p class="teacher-work">
												Executive Vice Principal
											</p>
											<p class="describe">
												（网络翻译 web translate）Master Degree from Beijing Foreign Language School, Deputy Director of      Guangdong Youth Artificial Intelligence Professional Committee, Nanhai District Information Technology Gold Coach. Served within the Nanhai Foreign Language School and many other international schools, including as a founding leader.</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20200725/159fde1da611a42af5d616daf6f6a6d9.png" alt="">
											</div>
											<p class="teacher-name">
												Ted Zse
											</p>
											<p class="teacher-work">
												Art & Design Consultant/VP
											</p>
											<p class="describe">
												Graduated from the University of the Arts London; UK professional reviewer and verifier for Pearson BTECs. Responsible for the academic coordination and postgraduate courses of the London University of the Arts in China, and successfully helped thousands of students of the region’s artists enter leading universities internationally. </p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/00f058c64ff277f7f6a0b8a5113f684a.png" alt="">
											</div>
											<p class="teacher-name">
												Angela Li
											</p>
											<p class="teacher-work">
												Director of Resource Development
											</p>
											<p class="describe">
												（网络翻译 web translate）Graduated from the University of California, San Diego, with a bachelor's degree in management science (graduated with Latin honors), a double associate degree in business and psychology, and a market strategy certificate from Cornell University. He has worked in the US consulting industry and Hong Kong's venture capital industry, including the United Kingdom and the United States. , The rich educational resources of many universities in Hong Kong, China.</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220801/0b7a97e239bb433f2b33839ccda82d14.jpg" alt="">
											</div>
											<p class="teacher-name">
												Martin Bills
											</p>
											<p class="teacher-work">
												Dean of the School of Music
											</p>
											<p class="describe">
												Masters of Composition from the University of Huddersfield in the UK. Has taught music composition and appreciation in many universities within the UK including Leeds Conservatory, Truro College and Falmouth University. For nine years he was the head of an innovative and contemporary world Jazz degree program based in Cornwall. 
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20210719/fc1300635e31c3b9dc6af82d08f5d65b.png" alt="">
											</div>
											<p class="teacher-name">
												Edison Yang
											</p>
											<p class="teacher-work">
												Director of Teaching and Research Office/Director 
											</p>
											<p class="describe">
												（网络翻译 web translate）Graduated from Curtin University in Australia and Hong Kong University with a postgraduate degree, majoring in Accounting and Finance. Australian Certified Public Accountant, worked for an international financial services institution. Before joining Chenmei, he taught in Chinese and Australian universities and served as course internal auditor and deputy dean of teaching supervisor. He holds the Australian Higher Education Teacher Qualification, the International Baccalaureate Teacher Certificate issued by the International Baccalaureate Organization, and the Cambridge University Examination Board English Teaching Ability Certificate.
											</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/8285f45fed7b8da89f06044dc00c20fe.png" alt="">
											</div>
											<p class="teacher-name">
												Brian Reverma
											</p>
											<p class="teacher-work">
												Academic Advisor
											</p>
											<p class="describe">
												（网络翻译 web translate）Bachelor of Arts, University of Dayton, M.A., Ohio State University, Artist and educator with over 30 years of experience, author of the "A Practical Guide for Idealistic Art Teachers" series, Producer of ISB Art History Channel on YouTube, Visual Arts Consultant of Inspire Citizens , Manager of the Asian Regional Art Educators Association (Asia Regional Art Teachers Alliance).</p>
										</div>
									</div>
						      <div class="swiper-slide">
										<div class="teacher-introduce">
											<div class="teacher-img">
												<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20201014/9da91f58cd585a177ce895edadd68b79.png" alt="">
											</div>
											<p class="teacher-name">
												Colin Kay
											</p>
											<p class="teacher-work">
												Academic Advisor
											</p>
											<p class="describe">
												（网络翻译 web translate）Graduated from the University of Cambridge, holds an MBA degree in International Teaching Leadership, a member of the Royal Society of Arts, served as a British high school principal for many years, is currently an educational consultant for Bristol College Trust, and an associate lecturer for the postgraduate course in educational leadership at the University of Bath.</p>
										</div>
									</div>
						
						    </div>
						    
						    <div class="ture-left-botton"></div>
						    <div class="ture-right-button"></div>
						  </div>	
					</div>
					<img class="more-img" src="../../../public/static/img/more-black.png" @click="comeMyself(`/about/architectureAndTeam`)" alt="">
				</div>	
				<div class="object w">
					<div class="object-box">
						<img class="object-icon" src="../../../public/static/img/item03-img1.png" alt="">
						<img class="object-title-icon" src="../../../public/static/img/item03-img3.png" alt="">
						<img class="object-teacher-img" src="../../../public/static/img/item03-img2.jpg" alt="">
						<div class="object-title">
							课程介绍
						</div>
						<div class="object-content">
							
Curriculum is the core of school education and the blueprint for the school to train future talents. The three-dimensional curriculum system constructed by Chenmei enables Chenmeizi to achieve all-round growth from outstanding students, outstanding social citizens to outstanding artists. Through master workshops, honors courses at prestigious schools, prestigious summer schools and other advanced academic projects, students can obtain high-quality professional growth and lay a solid foundation for becoming outstanding artists.         
						</div>
					</div>
					<div class="school-object-list">
						<div class="sisa-object" @click="comeMyself(`/teaching/sixCollegesList`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img4.png" alt="">
							<div class="sisa">
								<div class="sisa-title">
									SISA
								</div>
								<div class="sisa-content">艺术学院</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/teaching/commercial`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img5.png" alt="">
							<div class="sisa">
								<div class="sisa-title">SIBC</div>
								<div class="sisa-content">商学院</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/planning/service`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
							<div class="sisa">
								<div class="sisa-title">UNIVERSITY COUNSELING</div>
								<div class="sisa-content">升学</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
					</div>
					<div class="object-more">
						<img class="object-more-img" src="../../../public/static/img/more-white.png" @click="comeMyself(`/teaching/sixColleges`)" alt="">
					</div>
				</div>
				<div class="school-style w">
					<div class="school-style-box">
						<img class="style-img" src="../../../public/static/img/item04-img2.png" alt="">
						<img class="style-title-icon" src="../../../public/static/img/item04-img3.png" alt="">
						<img class="style-icon" src="../../../public/static/img/item04-img1.png" alt="">
						<div class="school-style-title">校园风采</div>
						<div class="school-style-txt need-line">
          The core of art education is to cultivate the aesthetic creativity of future talents. As a diversified and urbanized modern art school, Chenmei is a witness of Chinese aesthetic education. Chenmei is not just an independent school, it is also a regional art stimulator that can connect social resources and become a place for learning and practice. Art exhibitions, aesthetic education forums, large-scale ceremonies and evenings, student works display, creative courses...In Chenmei, students can show their ideas and creativity to the fullest and become a China that focuses on collaboration and communication, has leadership and collaboration skills, and has global competence. Artistic creative talents.         </div>
					</div>
					<div class="school-object-list">
						<div class="sisa-object" @click="comeMyself(`/information/rules`)">
							<img class="sisa-img" src="../../../public/static/img/item04-img4.png" alt="">
							<div class="sisa">
								<div class="sisa-title">
									Admissions and Prospect
								</div>
								<div class="sisa-content">招生简章</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/life/campusNews`)">
							<img class="sisa-img" src="../../../public/static/img/item04-img5.png" alt="">
							<div class="sisa">
								<div class="sisa-title">News</div>
								<div class="sisa-content">校园动态</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
						<div class="sisa-object" @click="comeMyself(`/life/TVstation`)">
							<img class="sisa-img" src="../../../public/static/img/item03-img6.png" alt="">
							<div class="sisa">
								<div class="sisa-title">Videos</div>
								<div class="sisa-content">原创视频</div>
							</div>
							<div class="sisa-mask">
								
							</div>
						</div>
					</div>
					<div class="object-more">
						<img class="object-more-img" src="../../../public/static/img/more-white.png" alt="">
					</div>
				</div>
				<div class="greet-link">
					<p class="greet-title">辰美欢迎您</p>
					<img class="greet-title-icon" src="../../../public/static/img/item05-img1.png" alt="">
					<p class="greet-txt">
						
        SISA is just waiting for you! Click on the link below
        <br />Learn about the information art of Chenmei's enrollment introduction, admission method, registration, etc.
      
					</p>
					<img class="greet-message" src="../../../public/static/img/icon-more2G.png" @click="comeMyself(`/information/rules`)" alt="">
					<div class="greet-my">
						<img src="../../../public/static/img/icon-more3G.png" @click="comeMyself(`/contact/Consultation`)" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
        联系电话：0757-86796295
        </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			<div class="ip-come" @click="detailsRight">
				系统入口
			</div>
			<div class="come-top" :class="{
				'come-top-show': showBtn,
				'come-top-hidden': hiddenBtn,
			}" @click="comeTop"></div>
			<div class="details-bg" v-if="detailShow">
				<div class="details-box">
					<div class="oa-box">
						<div class="oa">
							<a href="https://sisa.managebac.cn/login">
								<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
								<p>ManageBac</p>
							</a>
						</div>
					</div>
					<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
				</div>
			</div>
			<div class="sidebar">
				<img class="sidebar-img1" src="../../../public/static/img/边栏-在线报名.png" @click="comeMyself(`/information/apply`)" alt="">
				<img class="sidebar-img2" src="../../../public/static/img/边栏-实时资讯.png" @click="comeMyself(`/information/message`)" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// import Swiper from "../../../node_modules/swiper/swiper-bundle.min.js"
	import Swiper from "../../api/swiper.min.js"
	// import SwiperSlide from "vue-awesome-swiper"
	export default{
		name:'index',
		data(){
			return{
				active:0,
				detailShow:false,
				showBtn:false,
				hiddenBtn:true,
			}
		},
		mounted() {
			this.myswiper()
			this.swiperCon()
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},	
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			myswiper(){
				var swiper = new Swiper(".mySwiper", {
				  spaceBetween: 20,
				  centeredSlides: true,
				  autoplay: {
				    delay: 2500,
				    disableOnInteraction: false,
				  },
				  pagination: {
				    el: ".swiper-pagination",
				    clickable: true,
				  },
				  navigation: {
				    nextEl: ".swiper-button-next",
				    prevEl: ".swiper-button-prev",
						
				  },      
			 });	
			},
			swiperCon(){
				var swiper = new Swiper('.swiper-container', {
				      slidesPerView: 6,
				      spaceBetween: 30,
					  // centeredSlides: true,
					  // loop: true,
				      pagination: {
				        el: '.swiper-pagination',
				        clickable: true,
				      },
							navigation: {
							  nextEl: ".ture-right-button",
							  prevEl: ".ture-left-botton",
								disabledClass: 'my-button-disabled',
							},
							on:{
								transitionEnd:()=>{
									this.active = swiper.activeIndex;
									console.log(this.active)
								}
							}
				    });
						 var lis = document.querySelectorAll(".swiper-slide");
						 var list = document.getElementsByClassName("swiper-container ")[0];						 
						 lis.forEach(item => {
						     item.onmouseover = () => {
									 var x = list.childNodes[0].childNodes[this.active];
						        //先全部清空选中，再给当前添加选中
						        lis.forEach(item => item.classList.remove('swiper-slide-active'));
										console.log(x)
										// console.log(item.classList)
						        item.classList.add('swiper-slide-active');
						     }
						 })
						 lis.forEach(item => {
						     lis.forEach(item => {
						         item.onmouseout = () => {
												var x = list.childNodes[0].childNodes[this.active];
						            //先全部清空选中，再给当前添加选中
						            lis.forEach(item => item.classList.remove('swiper-slide-active'));
												x.classList.add('swiper-slide-active');
						         }
						     })
						 })
					// swiper.progress;
					// var pe = document.getElementById('progressEffect');
					// pe.onchange = function(){ 
					//     effect = this.value
					// 	swiper.update();	
					// }
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
	@import url("../../../node_modules/swiper/swiper-bundle.css");
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 7.66rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
.middle{
	margin: 0;
	padding: 0;
	/* width: 100%; */
	/* height: ; */
}
.banner img{
	width: 100%;
	height: 9.21rem;
	margin-top: 1.23rem;
	display: flex;
}
.info{
	width: 100%;
	height: auto;
}
.info-item{
	position: relative;
	width: 14.41rem;
	height: 6.22rem;
	background-color: white;
	margin-top: 2.5rem;
	margin-bottom: 1.27rem;
}
.school-introduce{
	position: absolute;
	left: 0;
	font-weight: 400;
	color: #3e3a39;
	top: .02rem;
	font-size: .4rem;
	line-height: .4rem;
}
.school-img{
	position: absolute;
	width: 5.08rem;
	position: absolute;
	left: .02rem;
	top: .58rem;
	z-index: 2;
}
.school-pic{
	width: 10.15rem;
	height: 5.7rem;
	position: absolute;
	right: 0;
	bottom: .52rem;
	z-index: 1;
}
.school-img img{
	width: 100%;
	height: 100%;
}
.school-pic img{
	width: 100%;
	height: 100%;
}
.school-txt{
	position: absolute;
	width: 3.6rem;
	font-size: .16rem;
	line-height: .28rem;
	letter-spacing: -.01rem;
	text-align: justify;
	top: 3.05rem;
	left: 0;
	font-weight: 400;
	color: #3e3a39;
}
.school-icon{
	width: 1.05rem;
	height: auto;
	position: absolute;
	right: -.52rem;
	bottom: 0;
	z-index: 2;
}
.team-title{
	width: 100%;
	margin-bottom: .35rem;
}
.school-team{
	height: 8.66rem;
	margin-bottom: 4.38rem;
	position: relative;
}
.title-img img{
	display: block;
	margin: 0 auto;
	width: 3.68rem;

}
.team-txt{
	font-size: .4rem;
	padding: 0;
	margin-top: .1rem;
	text-align: center;
}
.teacher{
	margin: 0 .34rem;
	padding: .57rem .41rem 0;
	overflow: hidden;
}
.teacher-introduce{
	width: 100%;
	height: 100%;
}
.teacher-name{
	font-size: .16rem;
	font-weight: 700;
	padding: 0;
	color: #1f2c5c;
	opacity: 1;
}
.teacher-work{
	font-size: .16rem;
	font-weight: 700;
	padding: 0;
	color: #1f2c5c;
	opacity: 1;
}
.teacher .describe{
	font-size: .14rem;
	line-height: .24rem;
	width: 2.44rem;
	margin: .06rem -.4rem;
	height: auto;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-align: justify;
	opacity: 0;
	transition: all .4s;
	-webkit-transition: all .4s;
	border-top: 1px solid #bab9b9;
	border-bottom: 1px solid #bab9b9;
	padding: 2px 0;
}
/* .teacher-introduce:hover .describe{
	opacity: 1;
} */
/* .teacher-introduce:hover .teacher-img img{
	padding: .15rem;
	border-radius: 50%;
	cursor: pointer;
	z-index: 10;
	border: .01rem solid #939ab1;
	transition: 1s;
	margin-bottom: 30px;
	transform: scale(1.5);
} */
.ture-right-button{
	    background: url(//sisa.p1.yixininteractive.com/pro/sisa/icon/icon-right.png) no-repeat top/100% auto;
	    width: .39rem;
	    height: 1.14rem;
	    position: absolute;
	    top: 2.05rem;
	    right: -.1rem;
}
.teacher .ture-left-botton{
	background: url(//sisa.p1.yixininteractive.com/pro/sisa/icon/icon-left.png) no-repeat top/100% auto;
	    width: .39rem;
	    height: 1.14rem;
	    position: absolute;
	    top: 2.05rem;
	    left: -.1rem;

}
.swiper {
    width: 100%;
    /* height: 100%; */
		overflow: hidden; 
}
.banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
/*    display: -webkit-flex;
    display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.banner .swiper-wrapper{
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}
.banner .swiper-slide img {
    width: 100%;
        height: 9.21rem;
        margin-top: 1.23rem;
        display: flex;
}
    .swiper-container {
      width: 100%;
      height: auto;
			/* padding:90px 0; */
      margin-left: auto;
      margin-right: auto;
			/* overflow: hidden; */
    }
  .swiper-container  .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
/* 			width: 121.975px !important;
			margin-right: 42.83px !important; */
      /* Center slide text vertically */
       display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
       /*     -webkit-align-items: center;
            align-items: center; */
    }
	.teacher  img{
		width: 1.33rem;
		height: 1.33rem;
		border-radius: 50%;
		/* transition: all 0.5s; */

		/* align-items: center; */

	}
	.more-img{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 8rem;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.swiper-slide-active .teacher-introduce .teacher-img img{
		padding: .15rem;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: .01rem solid #939ab1;
		/* border: 1px solid #000; */
		/* opacity: 0.2; */
		transition: 0.6s;
		margin-bottom: 30px;
		transform: scale(1.5);
		position: relative;
		z-index: 10;
	}
	.swiper-slide-active .describe{
		opacity: 1;
	}
	.my-button-disabled{
		opacity: 0;
	}
	.object-box{
		width: 14.41rem;
		height: 6.65rem;
		margin-bottom: 1.19rem;
		position: relative;
	}
	.object-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		left: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.object-teacher-img{
		width: 9.19rem;
		height: 6.13rem;
		position: absolute;
		left: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.object-title-icon{
		width: 7.22rem;
		position: absolute;
		right: .02rem;
		top: .75rem;
		z-index: 2;
	}
	.object-title{
		position: absolute;
		right: 0;
		top: .18rem;
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 400;
    color: #fff;
	}
	.object-content{
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		width: 4.6rem;
		font-size: .16rem;
		line-height: .28rem;
		text-align: justify;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		top: 3.3rem;
	}
	.sisa-object{
		cursor: pointer;
		position: relative;
	}
	.school-object-list{
		display: flex;
		justify-content: space-between;
	}
	.sisa-img{
		width: 4.59rem!important;
		height: 4.59rem!important;
	}
	.sisa{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 1rem;
		z-index: 3;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
	.sisa-title{
		font-weight: 700;
		font-size: .28rem;
		line-height: .46rem;
		border-bottom: 2px solid #fff;
	}
	.sisa-content{
		font-weight: 400;
		font-size: .2rem;
		line-height: .28rem;
		margin-top: .27rem;
	}
	.sisa-title,.sisa-content{
		color: #fff;
		transition: all .3s;
		-webkit-transition: all .3s;
	}
	.sisa-mask{
		position: absolute;
		top: 0;
		width: 4.59rem;
		height: 4.59rem;
		opacity: 0;
		background-color: rgba(0,0,0,.4);
		z-index: 2;
	}
	.sisa-object:hover .sisa-mask{
		transition: 0.6s;
		opacity: 1;
	}
	.sisa-object:hover .sisa-content{
		transition: 0.6s;
		font-size: .27rem;
	}
	.sisa-object:hover .sisa-title{
		transition: 0.6s;
		font-size: .32rem;
	}
	.object-more{
		margin-top: .89rem;
		width: 1.22rem;
		position: relative;
		left: 44%;
	}
	.object-more-img{
		position: absolute;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.object{
		width: 14.41rem;
		margin: auto auto 6.34rem;
	}
	.school-style-box{
		width: 14.41rem;
		height: 6.22rem;
		margin-bottom: 1.19rem;
		position: relative;
	}
	.style-img{
		width: 10.14rem;
		height: 5.7rem;
		position: absolute;
		right: 0;
		bottom: .52rem;
		z-index: 1;
	}
	.style-title-icon{
		width: 8.89rem;
		position: absolute;
		left: .02rem;
		top: .59rem;
		z-index: 2;
	}
	.style-icon{
		width: 1.05rem;
		height: auto;
		position: absolute;
		right: -.52rem;
		bottom: 0;
		z-index: 2;
	}
	.school-style-title{
		top: .01rem;
		left: 0;
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		font-size: .4rem;
		line-height: .4rem;
	}
	.school-style-txt{
		position: absolute;
		right: 0;
		font-weight: 400;
		color: #fff;
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
		width: 3.6rem;
		font-size: .16rem;
		line-height: .28rem;
		text-align: justify;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.school-style{
		margin-bottom: 7.68rem;
	}
	.greet-link{
		padding-bottom: 7.77rem;
	}
	.greet-title{
		font-size: .4rem;
		line-height: .4rem;
		font-weight: 400;
		color: #3e3a39;
		margin-bottom: .21rem;
		text-align: center;
	}
	.greet-title-icon{
		width: 4.54rem;
		height: auto;
		display: block;
		margin: auto auto .9rem;
	}
	.greet-txt{
		font-size: .2rem;
		font-weight: 400;
		color: #3e3a39;
		line-height: .28rem;
		text-align: center;
		margin-bottom: .45rem;
	}
	.greet-message{
		width: 1.22rem;
		left: 7.8rem;
		position: absolute;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.greet-my{
		left: 10.18rem;
		width: 1.22rem;
		cursor: pointer;
		position: relative;
	}
	.greet-my img{
		position: absolute;
		width: 1.22rem;
		margin: auto;
		display: block;
		cursor: pointer;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.sidebar{
		width: 1.08rem;
		height: 2.16rem;
		position: fixed;
		right: 0;
		bottom: 2rem;
		z-index: 99;
		flex-direction: column;
		display: flex;
	}
	.sidebar img{
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
	}
	.need-line{
		top: 3rem;
		left: 0;
		-webkit-line-clamp: 11;
		letter-spacing: -.01rem;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.sidebar-img1{
		cursor: pointer;
	}
	.sidebar-img2{
		cursor: pointer;
	}
</style>