<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span @click="comeMyself(`/contactCN/Consultation`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeIndex">Home</div>
							<div class="item" >
								<p @click="comePresidnt">About Us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">Principal Message</div>
									<div class="drop-down-item" @click="comeintro">Introduction</div>
									<div class="drop-down-item" @click="comeVisit">Campus Tour</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">Structure and Management Team</div>
									<div class="drop-down-item" @click="comeCo">Our Partners</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeTeaching">Teaching</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA</div>
									<div class="drop-down-item" @click="comeCommercial">SIBC</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL</div>
									<div class="drop-down-item" @click="comeBtec">BTEC</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">News</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">Campus News</div>
									<div class="drop-down-item" @click="comeTVstation">Videos</div>
									<div class="drop-down-item" @click="comeCalendar">Calendar</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeService">University Counseling</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">University Counseling</div>
									<div class="drop-down-item" @click="comeCollege">Foreign art schools</div>
									<div class="drop-down-item" @click="comeForeignCommercial">Foreign Business Colleges</div>
								</div>
							</div>
							<div class="item ">
								<p @click="comeStory">Admissions</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">SISA Story</div>
									<div class="drop-down-item" @click="comeMyself(`/information/rules`)">Admissions Brochure</div>
									<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">New Student Scholarship</div>
									<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/information/apply`)">Online Registration</div>
									<div class="drop-down-item" @click="comeMyself(`/information/message`)">Message</div>
								</div>
							</div>
							<div class="item active">
								<p>Contact us</p>
								<div class="drop-down-list">
									<div class="drop-down-item">Contact</div>
									<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">Recruitment</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/contactbg.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p class="nav-active">Contact</p>
						<p @click="comeMyself(`/contact/recruit`)">Recruitment</p>
					</div>
					<div style="display: none;">
						<p @click="comeStory">辰美故事</p>
						<p @click="comeMyself(`/information/rules`)">招生简章</p>
						<p @click="comeMyself(`/information/scholarship`)">新生奖学金</p>
						<p @click="comeMyself(`/information/qanda`)">Q&A</p>
						<p @click="comeMyself(`/information/apply`)">在线报名</p>
						<p class="nav-active">留言咨询</p>
					</div>
					<div style="display: none;">
						<p >升学服务</p>
						<p class="nav-active">国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p @click="comeCampusNews" >校园动态</p>
						<p class="nav-active">校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p @click="comeTeaching" >SISA艺术学院</p>
						<p @click="comeCommercial">SIBC商学院</p>
						<p @click="comeIgandAlevel">IGCSE、A-level学制</p>
						<p class="nav-active">BTEC学制</p>
						<!-- <p >辰美合作单位</p> -->
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					
					
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<img src="../../../public/static/img/brief-img1.jpg" class="img" alt="">
						<p class="text18R">
								辰美艺术教育（SISA）是全国首个根植中国、面向全球的艺术全学科（艺术与设计、音乐、戏剧、艺术商业）教育平台，也是湾区首所剑桥、爱德思双认证的艺术学院。辰美以培养“具有全球胜任力的中国创新人才”为使命，坚持根深中华、融通世界、美育全球，办面向未来、引领未来、成就未来的艺术教育。辰美精心设计的城市校园给学子提供充满想象力与创造力的学习空间，辰美独创丰富多元的课程体系，探索激发学生活力的办学模式，通过特有的“博雅教育”与“胜任力模型”，融合中西文化、艺术与商科综合实践教育，培养具有审美能力、设计思维、美学创造力、全球胜任力的中国未来艺术家和商业领袖，为中国学子提供全方位、一站式海内外名校升学方案。
						</p>
						<P class="text18R">
							<br>
							Stellart is a global art education platform for everyone, authorized by Cambridge Assessment International Education (CAIE) and Pearson Edexcel, offering IGCSE, A-LEVEL and NCUK IFY pre-university programs. 
						</P>
						<p class="title50">联系方式</p>
						<p class="text18R">
							
							1、辰美艺术教育招生办前台联系电话：0757-86796295
							<br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;招生办开放时间：每天 9:30-17:30（春节除外）
							<br>
							2、微信公众号：辰美艺术（ID：sisacn）
							<br>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;富罗恩斯广场15栋2楼（佛罗伦萨小镇对面）
							<br>
							3、辰美艺术教育招生办老师联系方式
						</p>
						<div class="box-3 flex flex-hsb">
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img10.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">席老师-Dylan Xi</p>
									<p class="text14R">手机：13927252170</p>
									<p class="text14R">邮箱：
									Dylan.xi@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img11.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">张老师-Flora Zhang</p>
									<p class="text14R">手机：13927252209</p>
									<p class="text14R">邮箱：
									Flora.zhang@sisa.cn</p>
								</div>
							</div>
							<div class="flex box2-item">
								<img src="../../../public/static/img/brief-img12.jpg" alt="">
								<div class="flex-v flex">
									<p class="text16B">陈老师-Joe Chen</p>
									<p class="text14R">手机：13927255044</p>
									<p class="text14R">邮箱：
									Joe.chen@sisa.cn</p>
								</div>
							</div>
						</div>
						<p class="title50">咨询方式</p>
						<p class="text18R">
							
							1、现场咨询：亲临辰美招生办公室；
							<br>
							2、网上咨询：登录学校网站报名（www.sisa.cn）或关注学校官方微信“辰美艺术”，在线留言咨询。
							<br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'Consultation',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
			}
		},
		
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeCommercial(){
				this.$router.push("/teaching/commercial");
			},
			comeIndex(){
				this.$router.push("/index");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 7.66rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.img{
		width: 100%;
		height: auto;
		margin-bottom: .7rem;
	}
	.text18R, .text22R {
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R {
		font-size: .18rem;
		text-align: justify;
	}
	.title50 {
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .22rem;
	}
	.title50{
	  margin: .97rem 0 .64rem;
	}
	.box-3{
	  margin-top: .51rem;
	}
	.flex-hbet, .flex-hsb {
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		-o-justify-content: space-between;
		justify-content: space-between;
	}
	.flex {
		display: flex;
	}
	.box2-item img {
		width: 1.1rem;
		height: 1.1rem;
	}
	.flex-v {
		-moz-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}
	.box2-item .text16B{
	  margin-top: .43rem;
	}
	.text16B {
		font-size: .16rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .22rem;
	}
	.text14B, .text14R {
		font-size: .14rem;
		color: #0e183f;
	}
	.text14R {
		font-weight: 400;
		line-height: .22rem;
	}
</style>
