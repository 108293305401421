import { render, staticRenderFns } from "./co.vue?vue&type=template&id=2238d50e&scoped=true&"
import script from "./co.vue?vue&type=script&lang=js&"
export * from "./co.vue?vue&type=script&lang=js&"
import style0 from "./co.vue?vue&type=style&index=0&id=2238d50e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2238d50e",
  null
  
)

export default component.exports