<template>
	<div class="all-content">
		<div class="header">
			<div class="w header-top">
				<div class="header-logo">
					<img src="../../../public/static/img/sasi-logo.png" alt="" srcset="">
				</div>
				<div class="header-content">
					<div class="header-lan" style="cursor: pointer;">
						<span @click="comeMyself(`/teachingCN/commercial`)">•&nbsp;中文</span>&nbsp;/&nbsp;<span class="active">ENG</span>
					</div>
					<div class="header-list">
						<div class="header-list-box">
							<div class=" item item1" @click="comeIndex">Home</div>
							<div class="item" >
								<p @click="comePresidnt">About Us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comePresidnt">Principal Message</div>
									<div class="drop-down-item" @click="comeintro">Introduction</div>
									<div class="drop-down-item" @click="comeVisit">Campus Tour</div>
									<div class="drop-down-item" @click="comeArchitectureAndTeam">Structure and Management Team</div>
									<div class="drop-down-item" @click="comeCo">Our Partners</div>
								</div>
							</div>
							<div class="item active">
								<p @click="comeTeaching">Teaching</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeTeaching">SISA</div>
									<div class="drop-down-item">SIBC</div>
									<div class="drop-down-item" @click="comeIgandAlevel">IGCSE、ALEVEL</div>
									<div class="drop-down-item" @click="comeBtec">BTEC</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeCampusNews">News</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeCampusNews">Campus News</div>
									<div class="drop-down-item" @click="comeTVstation">Videos</div>
									<div class="drop-down-item" @click="comeCalendar">Calendar</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeService">University Counseling</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeService">University Counseling Service</div>
									<div class="drop-down-item" @click="comeCollege">国外艺术院校</div>
									<div class="drop-down-item" @click="comeForeignCommercial">国外商科院校</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeStory">Admissions</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeStory">SISA Story</div>
									<div class="drop-down-item" @click="comeRules">Admissions Brochure</div>
									<div class="drop-down-item" @click="comeMyself(`/information/scholarship`)">New Student Scholarship</div>
									<div class="drop-down-item" @click="comeMyself(`/information/qanda`)">Q&A</div>
									<div class="drop-down-item" @click="comeMyself(`/information/apply`)">Online Registration</div>
									<div class="drop-down-item" @click="comeMyself(`/information/message`)">Message</div>
								</div>
							</div>
							<div class="item">
								<p @click="comeMyself(`/contact/Consultation`)"> Contact us</p>
								<div class="drop-down-list">
									<div class="drop-down-item" @click="comeMyself(`/contact/Consultation`)">Contact</div>
									<div class="drop-down-item" @click="comeMyself(`/contact/recruit`)">Recruitment</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="words">
			<img class="words-img" src="../../../public/static/img/teachingbg.5cb1fa8.png" alt="">
			<div class="event">
				<div class="left-nav">
					<div>
						<p @click="comeTeaching" >SISA</p>
						<p class="nav-active">SIBC</p>
						<p  @click="comeIgandAlevel">IGCSE、A-level</p>
						<p @click="comeBtec">BTEC</p>
					</div>
					<div style="display: none;">
						<p class="">联系及咨询方式</p>
						<p>招聘信息</p>
					</div>
					<div style="display: none;">
						<p class="">辰美故事</p>
						<p>招生简章</p>
						<p>新生奖学金</p>
						<p>Q&A</p>
						<p>在线报名</p>
						<p>留言咨询</p>
					</div>
					<div style="display: none;">
						<p class="">校园动态</p>
						<p>校园原创视频</p>
						<p>校历</p>
					</div>
					<div style="display: none;">
						<p class="">社区资讯</p>
						<p>社区项目</p>
						<p>学生作品</p>
						<p>校友会</p>
					</div>
					<div style="display: none;">
						<p class="">致家长一封信</p>
						<p>家校互动</p>
						<p>家长委员会</p>
					</div>
					<div style="display: none;">
						<p class="">升学服务</p>
						<p>国外艺术院校</p>
						<p>国外商科院校</p>
					</div>
					<div style="display: none;">
						<p class="">SISA艺术学院</p>
						<p>SIBC商学院</p>
						<p>IGCSE、A-level学制</p>
						<p>BTEC学制</p>
					</div>
				</div>
				<div class="mid">
					<div class="content">
						<p class="title50">SISA商业学院</p>
						<p class="title30">SIBC商学院打造“六三一”课程体系</p>
						<p class="title30 bottom56">Innovative "Six-Three-One" Curriculum at SIBC</p>
						<p class="title22">1、国际学术课程占比60%，参加全球统一的考试。包括如下课程：</p>
						<p class="text18R">
							必修基础课程：数学、中文、英文 
							<br>
							必修专业课程：经济、商务/会计（注：三个专业课程学生至少选修其中一科或两科） 
							<br>
							选修学术课程：国际视野、艺术与设计、数字艺术、音乐（注：视学生个人兴趣和发展需要选修）
							<br>
							<br>
						</p>
						<p class="title22">2、综合素质课程占比30%，包括如下课程：</p>
						<p class="text18R">
							商业案例教学	
							<br>
							
							商业沙盘模拟教学
							<br>
							商务礼仪（包括仪表、服装、餐桌、通信、通话、宴会等礼仪）
							<br>
							商务技能（烘焙、插花、品酒品茶、PPT制作、演讲、交谊舞等）
							<br>
							艺术鉴赏
							<br>
							人文素养
							<br>
							运动与健康
							<br>
							社团活动
							<br>
							<br>
						</p>
						<p class="title22">3、社会实践占比10%，包括如下社会实践：</p>
						<p class="text18R">
							商业项目实践
							<br>
							商业公司（名企、投行、金融机构）实习
							<br>
							商业比赛
							<br>
							义工服务
						</p>
						<div class="img-4">
							<img src="../../../public/static/img/commercial-img1.jpg" alt="">
							<img src="../../../public/static/img/commercial-img2.jpg" alt="">
							<img src="../../../public/static/img/commercial-img3.jpg" alt="">
							<img src="../../../public/static/img/commercial-img4.jpg" alt="">
						</div>
						<p class="title30 title-lineS">行业教授大咖齐聚</p>
						<p class="title30 title-lineS bottom56">Working with the top-notch industry leaders</p>
						<p class="text18R">
							辰美高中商学院拥有一支顶级商科教授团队，行业成功的基金大佬、投行精英、金融专家等将成为我们的顾问、客座
							教授，为辰美学子成为商业精英提供强有力的支持。
							SIBC is supported from a top-notch faculty team, which is comprised of a group of fund managers, investment ban
							kers and finance experts. Our advisory panel and visiting lecturers provide students with high level mentoring, hel
							ping them develop into future business elites.
						</p>
						<div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-left">
											<div class="item-title">
												<p>李庆杰</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													基汇资本（Gaw Capital）华南区总经理，广州新光百货创始人，主持开发了广佛佛罗伦萨小镇、第壹时区、辰美国际艺术教育项目。
													<br>
													毕业于华南理工大学建筑结构工程系，清华大学管理研修班、上海复旦大学管理研修班，在中国拥有30年以上的房地产投资和管理经验。
												</div>
											</div>
										</div>
										<div class="item-right">
											<img src="../../../public/static/img/commercial-ren1.png" class="item-img1" alt="">
											<img src="../../../public/static/img/commercial-left.png" class="item-img2" alt="">
										</div>
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-right right-item">
											<img src="../../../public/static/img/commercial-ren2.png" class="item-img1 img-left" alt="">
											<img src="../../../public/static/img/commercial-right.png" class="item-img2" alt="">
										</div>
										<div class="item-left">
											<div class="item-title">
												<p>李学海</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													美国威特集团董事长兼首席执行长，现任中国侨商联合会副会长、天津侨商会会长、中国与全球化智库副主席。
													<br>
													曾任美国白宫总统亚太事务委员会总统顾问、美国联邦商业部少数族裔商业发展大会全国主席、美国纽约州政府公务人员退休基金顾问，曾担任清华大学五道口金融学院、北京大学经管学院、天津南开大学的客座教授。
												</div>
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-left">
											<div class="item-title">
												<p>彭说龙</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													华南理工大学工商管理学院研究员、博士生导师。曾担任华南理工大学党委常委及副校长、贵州省中共六盘水市委常委、市人民政府副市长，曾公派至美国、加拿大、英国等多个国家的院校进行培训与交流，参与多个高等教育与管理、企业管理与项目的策划，发表学术论文30余篇。
												</div>
											</div>
										</div>
										<div class="item-right">
											<img src="../../../public/static/img/commercial-ren3.png" class="item-img1" alt="">
											<img src="../../../public/static/img/commercial-left.png" class="item-img2" alt="">
										</div>
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-right right-item">
											<img src="../../../public/static/img/commercial-ren4.png" class="item-img1 img-left" alt="">
											<img src="../../../public/static/img/commercial-right.png" class="item-img2" alt="">
										</div>
										<div class="item-left">
											<div class="item-title">
												<p>苏祖耀</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													佛山政协委员、佛山市海外联谊会会长、佛山市佛山科学技术学院(Foshan University)校董、佛山市海外联谊会会长、粤港澳大湾区欧盟区块链节点创会主席。
													<br>
													曾任杜邦中国有限公司总经理、新日能控股有限公司(Nova Solar Holdings Limited)执行副总裁兼总经理，合伙创办人。
												</div>
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-left">
											<div class="item-title">
												<p>董博欣</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													澳大利亚莫那什大学商学硕士，香港CFA协会会员，国家会计学院、中国人民大学、上海财经大学、纽约金融学院兼职教授，累计培训过千名企业家与高管。
													<br>
													近五年管理私募股权基金规模逾二十亿元，深圳市产业引导基金管理人；投资与咨询项目：曾主导完成“卡特新能源”、“环亚医用”、“时代装饰”、“幸福西饼”等三十余家企业投资项目；主导完成长沙轨道、大唐电信等十余个财务咨询项目
												</div>
											</div>
										</div>
										<div class="item-right">
											<img src="../../../public/static/img/commercial-ren5.png" class="item-img1" alt="">
											<img src="../../../public/static/img/commercial-left.png" class="item-img2" alt="">
										</div>
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-right right-item">
											<img src="../../../public/static/img/commercial-ren6.png" class="item-img1 img-left" alt="">
											<img src="../../../public/static/img/commercial-right.png" class="item-img2" alt="">
										</div>
										<div class="item-left">
											<div class="item-title">
												<p>戴瑾辉</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													恒丰方德投资管理公司合伙人、执行董事、基金经理。英国剑桥大学计算机科学、信息工程学学士、硕士与荣誉文学硕士、帝国理工商学院量化金融硕士。
													<br>
													曾任职英国汇丰银行伦敦全球总部，主管新兴市场债券和利率衍生品交易操盘，专注动态风险对冲和团队量化交易策略开发与执行。具有中国基金经理从业资格，FCA及全球多地区认证的英国特许证券与投资协会（CISI）证券从业资格，含证券与衍生品 3 级证书。
												</div>
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-left">
											<div class="item-title">
												<p>Amy Yung</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													香港李宝椿联合世界书院、美国芝加哥大学经济系优秀毕业生，香港大学教育学硕士，曾任高盛纽约、伦敦和香港分部的投资研究分析师，基汇资本投资部总监，现任基汇资本教育平台运营总监，有丰富的投资行业经验，对教育有深刻的理解。
												</div>
											</div>
										</div>
										<div class="item-right">
											<img src="../../../public/static/img/commercial-ren7.png" class="item-img1" alt="">
											<img src="../../../public/static/img/commercial-left.png" class="item-img2" alt="">
										</div>
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-right right-item">
											<img src="../../../public/static/img/commercial-ren8.png" class="item-img1 img-left" alt="">
											<img src="../../../public/static/img/commercial-right.png" class="item-img2" alt="">
										</div>
										<div class="item-left">
											<div class="item-title">
												<p>Herbin Koh</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													耶鲁-新加坡国立大学学院经济系优秀毕业生，英国Pirate Studio Ltd董事会成员，曾创办多个教育机构并担任亚洲多个初创公司的投资人及顾问，现任香港基汇资本私募股权投资及资本市场总监，有多年的教育投资及募资经验。
												</div>
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-left">
											<div class="item-title">
												<p>陈卫钊</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													基汇资本（Gaw Capital）华南区财务总监，中山大学管理学院职业导师，负责基汇资本在华南地区多个大型商业地产项目的收购、退出以及日常运营的财务管理工作。
													<br>
													毕业于中山大学管理学院国际会计专业，毕业后在国内及国际知名会计师事务所从事多年审计及咨询工作。
												</div>
											</div>
										</div>
										<div class="item-right">
											<img src="../../../public/static/img/commercial-ren9.png" class="item-img1" alt="">
											<img src="../../../public/static/img/commercial-left.png" class="item-img2" alt="">
										</div>
									</div>
									
								</div>
							</div>
							<div>
								<div class="people-item item01">
									<div class=" item-top">
										<div class="item-right right-item">
											<img src="../../../public/static/img/commercial-ren11.png" class="item-img1 img-left" alt="">
											<img src="../../../public/static/img/commercial-right.png" class="item-img2" alt="">
										</div>
										<div class="item-left">
											<div class="item-title">
												<p>李雅靖</p>
												<div class="item-shu"></div>
												<p></p>
											</div>
											<div class="item-text2">
												<p>简介：</p>
												<div>
													现任辰美校务委员会资源拓展总监，毕业于美国加州大学圣地亚哥分校，获得管理科学学士（拉丁荣誉毕业）、商学和心理学双副学士、康奈尔大学市场战略证书。曾就职于美国咨询行业和香港风投行业，有英国、美国、中国香港多所大学的丰富教育资源。
												</div>
											</div>
										</div>
										
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<img class="footer-logo" src="../../../public/static/img/logo.png" alt="">
			<div class="footer-address">
				<p>网站版权归辰美所有</p>
				<p>广东省佛山市南海区三山新城疏港路26号辰美艺术教育</p>
				<p class="footer-txt">备案号：粤ICP备19030818号</p>
				<p class="footer-phone">
		    联系电话：0757-86796295
		    </p>
			</div>
			<div class="footer-icon">
				<div class="footer-wx">
					<img class="wx-img" src="../../../public/static/img/footer-wx.png" alt="">
					<p>关注我们</p>
					<img class="wx-code" src="../../../public/static/img/footer-code.png" alt="">
				</div>
			</div>
			
		</div>
		<div class="ip-come" @click="detailsRight">
			系统入口
		</div>
		<div class="come-top" :class="{
			'come-top-show': showBtn,
			'come-top-hidden': hiddenBtn,
		}" @click="comeTop"></div>
		<div class="details-bg" v-if="detailShow">
			<div class="details-box">
				<div class="oa-box">
					<div class="oa">
						<a href="https://sisa.managebac.cn/login">
							<img src="https://cmwebsite-api.oss-cn-shenzhen.aliyuncs.com/prod/20220713/c2bfccb30a8ae73ad6d0c4770961e34b.jpg" alt="">
							<p>ManageBac</p>
						</a>
					</div>
				</div>
				<img class="icon-close" src="../../../public/static/img/icon-close.png" alt="" @click="detailsClose">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'commercial',
		data(){
			return{
				showBtn:false,
				hiddenBtn:true,
				detailShow:false,
			}
		},
		
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
		},
		methods:{
			comeMyself(text){
				this.$router.push(text);
			},
			comeRules(){
				this.$router.push("/information/rules");
			},
			comeStory(){
				this.$router.push("/information/story");
			},
			comeForeignCommercial(){
				this.$router.push("/planning/foreignCommercial");
			},
			comeCollege(){
				this.$router.push("/planning/College");
			},
			comeService(){
				this.$router.push("/planning/service");
			},
			comeCalendar(){
				this.$router.push("/life/calendar");
			},
			comeTVstation(){
				this.$router.push("/life/TVstation");
			},
			comeCampusNews(){
				this.$router.push("/life/campusNews");
			},
			comeBtec(){
				this.$router.push("/teaching/btec");
			},
			comeIgandAlevel(){
				this.$router.push("/teaching/igandAlevel");
			},
			comeIndex(){
				this.$router.push("/index");
			},
			comeTeaching(){
				this.$router.push("/teaching/sixCollegesList");
			},
			detailsRight(){
				this.detailShow = true
			},
			detailsClose(){
				this.detailShow = false
			},
			comeCo(){
				this.$router.push("/about/co");
			},
			comeArchitectureAndTeam(){
				this.$router.push("/about/architectureAndTeam");
			},
			comeintro(){
				this.$router.push("/about/introduction");
			},
			comePresidnt(){
				this.$router.push("/about/presidentWords");
			},
			comeVisit(){
				this.$router.push("/about/visitCampus");
			},
			showbtn() {
				let that = this;
				let scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (scrollTop > 350) {
						this.showBtn = true;
						this.hiddenBtn = false;
				} else {
						this.showBtn = false;
						this.hiddenBtn = true;
				}
			},
			comeTop(){
				 var timer = setInterval(function () {
				                    let osTop =
				                        document.documentElement.scrollTop || document.body.scrollTop;
				                    let ispeed = Math.floor(-osTop / 5);
				                    document.documentElement.scrollTop = document.body.scrollTop =
				                        osTop + ispeed;
				                    this.isTop = true;
				                    if (osTop === 0) {
				                        clearInterval(timer);
				                    }
				                }, 30);         
			}
			
		}
	}
</script>

<style scoped>
.w{
	width: 14.41rem;
	margin: 0 auto;
}
.all-content{
	    background: #fff url(../../../public/static/img/indexpage-bg.png) repeat-y top/100% auto;
	    width: 100%;
	    height: auto;
}
.header{
	position: fixed;
	height: 1.23rem;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: white;
	box-shadow: 0.01rem 0.01rem 0.02rem #d2d0d0;
}
.header-top{
	background-color: white;
	height: 100%;
}
.header-logo{
	float: left;
	margin: 0;
	padding: 0;
}
.header-list{
	margin-top: auto;
	cursor: pointer;
}
.header-logo img{
	width: 2.23rem;
	margin-left: 1.3rem;
	height: auto;
	margin-top: 0.2rem;
	cursor: pointer;
}
.banner{
	width: 100%;
	height: 9.21rem;
}
.header-lan{
	padding-left: 7.66rem;
	margin-top: .35rem;
	margin-bottom: 0.12rem;
	position: relative;
}

.header-content{
	
	float: right;
	font-size: .18rem;
	color: #000;
	font-weight: 500;
	margin-bottom: .32rem;
}
.header-list-box{
	justify-content: space-between;
	display: flex;
}
.item{
	position: relative;

	margin-right: .44rem;
	padding-bottom: .09rem;
	border-bottom: .05rem solid hsla(0,0%,100%,0);
	white-space: nowrap;
	height: .33rem;
}
/* .item1{
	padding-top: .18rem;
	padding-bottom: 0rem;
} */
.item:hover .drop-down-list{
	display: block;
}
.drop-down-list{
	position: absolute;
	top: .47rem;
	left: 0;
	width: 2.03rem;
	background-color: #992b2d;
	z-index: 99;
	display: none;
	font-weight: 500;
}
.drop-down-list .drop-down-item{
	color: #fff;
	margin: .15rem 0 .15rem 8px;
}
.drop-down-list .drop-down-item:hover{
	color:#dd902d
}
.active{
	color: #992b2d;
}
.item:hover{
	border-bottom: .05rem solid #992b2d;
}
	.words-img{
		margin-top: 1.053rem;
		width: 100%;
		display: flex;
	}
	.event{
		background: url(../../../public/static/img/container-bg2.png) repeat-y top/100% auto;
		display: flex;
		position: relative;
	}
	.left-nav{
		position: absolute;
		width: 1.37rem;
		top: .70rem;
		left: .87rem;
	}
	.left-nav p{
		font-size: .18rem;
		line-height: .37rem;
		font-weight: 400;
		color: #000;
		margin-left: .2rem;
		margin-bottom: .55rem;
		padding-left: .12rem;
		cursor: pointer;
		white-space: nowrap;
	}
	.left-nav p:hover{
		color:#f8b62d
	}
	.left-nav .nav-active{
		width: 1.99rem;
		height: .37rem;
		background: #000;
		color: #fff !important;
	}

	.split-line2{
		margin: 0 .41rem 0 .4rem;
		display: block;
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
	}
	.come-top{
		width: .56rem;
		/* display: none; */
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 2;
		height: .56rem;
		opacity: 0;
		background: url(../../../public/static/img/icon-top.png) no-repeat top/100% auto;
		top: 8rem;
		cursor: pointer;
	}
	.come-top-hidden{
		opacity: 0;
		-webkit-animation: fadeOut 2s linear;
		animation: fadeOut 2s linear;
	}
	.come-top-show{
		display: block;
		opacity: 1;
		cursor: pointer;
		transition: 3s;
	}
	.mid{
		width: 11.15rem;
		margin: .72rem auto 1rem;
		background: #fff;
	}
	.mid .content{
		margin: 1rem .8rem;
		min-height: 5rem;
	}
	.footer{
		width: 100%;
		height: 2.6rem;
		background-color: #222220;
		display: flex;
		font-size: .14rem;
		line-height: .15rem;
		font-weight: 400;
		color: #ededed;
	}
	.footer-logo{
		width: 2.76rem;
		height: 1.06rem;
		display: block;
		margin-left: 2.5rem;
		margin-right: .33rem;
		margin-top: .77rem;
	}
	.footer-address{
		flex: 1;
		height: 1.06rem;
		margin-top: .74rem;
		justify-content: space-between;
		flex-direction: column;
	}
	.footer-txt{
		color: #fff;
		cursor: pointer;
	}
	.footer-txt:hover{
		color:#fe7b00;
	}
	.footer-phone{
		align-items: center;
		display: flex;
	}
	.footer-icon{
		display: flex;
		height: 1.67rem;
		cursor: pointer;
		margin-top: .87rem;
		margin-right: 2rem;
	}
	.footer-wx{
		margin-right: 2.2rem;
		position: relative;
	}
	.footer p{
		margin: .15rem;
		margin-left: 0rem;
	}
	.footer-icon p{
		font-size: .12rem;
		line-height: .12rem;
		font-weight: 300;
		color: #ededed;
	}
	.wx-img{
		width: .32rem;
		height: auto;
		display: table-cell;
		vertical-align: bottom;
		margin: auto auto .05rem;
	}
	.wx-code{
		position: absolute;
		top: -.25rem;
		left: .8rem;
		margin: auto;
		width: 1.53rem;
		height: 1.6rem;
	}
	.ip-come{
		width: .56rem;
		display: block;
		position: fixed;
		right: 2.31rem;
		bottom: 1.53rem;
		z-index: 100;
		height: .51rem;
		background: #1d2b5d;
		top: 7.4rem;
		color: #fff;
		font-size: .22rem;
		line-height: .24rem;
		text-align: center;
		padding-top: .05rem;
		cursor: pointer;
	}
	.details-bg{
		text-align: center;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.76);
	}
	.details-box{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		background: url(../../../public/static/img/oa-bg.png) no-repeat 50%/100% 100%;
		width: 7.86rem;
		height: 5.66rem;
		border: 0;
		top: 0;
		bottom: 0;
	}
	.oa-box{
		width: 3.7rem;
		padding-top: 1.3rem;
		padding-left: 1rem;
	}
	.oa, .oa img {
	    width: 1.49rem;
	    height: 1.49rem;
	}
	.oa{
		position: relative;
		border: .02px solid #000;
		border-radius: .1rem;
		float: left;
		margin-right: .7rem;
		margin-bottom: .5rem;
	}
	.oa img,.oa p{
		position: absolute;
	}
	.oa p{
		font-size: .25rem;
		font-weight: 800;
		color: #fff;
		line-height: .25rem;
		bottom: .19rem;
		width: 100%;
		text-align: center;
		display: none;
	}
	.oa img{
		border-radius: .1rem;
		top: 0;
		left: 0;
	}
	a{
		text-decoration: none;
	}
	.icon-close{
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		cursor: pointer;
		width: .63rem;
		height: .63rem;
		bottom: -1.1rem;
	}
	.title50{
		font-size: .5rem;
		line-height: .5rem;
		font-weight: 700;
		color: #1e2c5c;
		margin-bottom: .96rem;
	}
	.title30{
		font-weight: 700;
		color: #0e183f;
		font-size: .3rem;
		line-height: .3rem;
		margin-top: 1rem;
		margin-top: 0;
		border-bottom: 1px solid #0e183f;
		padding-bottom: .06rem;
		display: inline-block;
	}
	.bottom56{
		margin-bottom: .56rem;
	}
	.title22{
		font-size: .22rem;
		font-weight: 700;
		color: #0e183f;
		line-height: .32rem;
	}
	.text18R{
		font-weight: 400;
		color: #0e183f;
		line-height: .32rem;
		font-size: .18rem;
		text-align: justify;
	}
	.img-4{
		display: flex;
		width: 100%;
		margin-top: .54rem;
		margin-bottom: .38rem;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.img-4 img{
		width: 4.6rem;
		height: 2.82rem;
		margin-bottom: .41rem;
	}
	.people-item{
		margin-bottom: .59rem;
	}
	.item01{
		margin-top: .95rem;
	}
	.item-top{
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 4.92rem;
		margin-bottom: .4rem;
	}
	.item-left{
		flex: 1;
		position: relative;
	}
	.item-title{
		display: flex;
		position: absolute;
		left: 0;
		align-items: center;
		line-height: .4rem;
		font-size: .34rem;
		font-weight: 700;
		color: #0e183f;
		top: 1.1rem;
		z-index: 2;
		white-space: nowrap;
	}
	.item-shu{
		width: 1px;
		height: .28rem;
		background-color: #0f193e;
		margin: 0 .16rem 0 .14rem;
	}
	.item-text2{
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: .14rem;
		font-weight: 300;
		color: #0e183f;
		line-height: .32rem;
		text-align: justify;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.item-right{
		width: 4.41rem;
		height: 4.92rem;
		position: relative;
		margin-left: .7rem;
	}
	.item-img1{
		width: 4.04rem;
		height: 4.92rem;
		position: absolute;
		left: unset;
		right: 0;
		top: 0;
	}
	.item-img2{
		width: 4.41rem;
		height: 4.92rem;
	}
	.img-left{
		left: 0;
	}
	.right-item{
		margin-left: 0;
			margin-right: .7rem;
	}
</style>
